import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63V3WrDIBTA8fs%2BheDNCu3QGKNxl0sfxGjs2rTNln1v7N2HKYyTMg4HNnL3r%2FlxNKFx4zA8sc8FY%2Bt10sf9cBCOcVEJZeMNqPLXWjjGlVcmBFiVY9waq1OCtXSMhyqU86od40mni7WVY7yLQlgJq8lrTeiigdXmmmzyCtZ6qp3oZmt9rjGppGFtp7WpTh7WkKvyQpWwRsd4jG1bz%2BbtHOMbqQvVwJrymQkt9WwXW8e40fmC9c4x7kO%2BYN05xkubL1j3kytg6s9bgOmQz7AQFwuPjvGmadRmdlqn%2FMSaW7uxN4uvxfU5Psxei8GxFz9e%2FbwlS3j%2F%2FfxHswTMiDESY3rIPGKMwhgJmSeMKcjTPGNMSZ7mBWM0mXnFmIq8qTeMMeRp3jGmJjMfGGPJjBT%2F5EjMaelOgTmB7ijMiXSnxJyO%2FPJIjTmJ7lSYs6U7BnPuMGc3cyzm7Ojz1Jizpzsec3r6c28x50CfJ2DOkT5PxJwT3en%2BeD7tEM%2F%2FYGE4DOPF415NX94JaX3ot%2BPwfIrrX1b2q%2BmDnMVvZ5V7SWMJAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62WzY6bMBSF93kKLxM1WP43ZlazmGVXfYCKgJO4JZABmvmp%2Bu5VCIQxXEujSbbX3I97zvFFJHVVtejvAqEo%2BkmdZhtHSYJOab2Mol37eLC1y9Kn19aWuc1X6%2BnJjzYt87Q%2BnzzWLi3WqEnLJmps7bYPHpXOqJ%2FvZTf08lnv96qsblEiEpQV6eG4ZJgyWdvDGmVpkS0jikVMVFdC35DGkrLTy2qNRP%2FcyufIgUOxmnCINgNGYsVFh%2BEwRo2YjxSCpeaG6x5DsSBxhxneNsHoEaM%2FYChmxnA6TEOw4abDsDkiDiForK62UEz1RQ%2FDBJzEjBjqK1JaQKNQfJE54aQhYzSsiGIAshkgHHuSlNE0HszlWEvVRw0wsnDQ8uzugFHnyPqoQUV5ctXqH9h7SN0GfY%2BNUSNEqAECpbcbKMS7BARLJgAIwTGkdB%2BiBG4jnSPcdU09xxWR0IrO%2B3%2BNdviZMSKA5ZwDfgf9FCKGNxNworhHtIe7RFuOocR%2BKoIFFhP8YlVBjhLgNHPEEXYlotgQAW7UnPF8ZXjXVARshZTUtytp7rIv7UhRfsAcDLh%2F2YTyJ0GCEL92SpCc1l4SpKa11wTpae0N6H1P0KRy%2FuEgD4t%2Fi02Vv3X%2FItuqbKPGvduv2lu40kZ763b79uvrU9i2tXXUHNPMlbvLjP8BBCJUtjAJAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMjRweDsKICAtLV8xYWNlYjRxMzogMjRweDsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuMTI1cmVtOwogIC0ta2RtdnFpNzogMC4yNXJlbTsKICAtLWtkbXZxaTg6IDAuMzc1cmVtOwogIC0ta2RtdnFpOTogMC41cmVtOwogIC0ta2RtdnFpYTogMC43NXJlbTsKICAtLWtkbXZxaWI6IDFyZW07CiAgLS1rZG12cWljOiAxLjI1cmVtOwogIC0ta2RtdnFpZDogMS41cmVtOwogIC0ta2RtdnFpZTogMnJlbTsKICAtLWtkbXZxaWY6IDIuMzc1cmVtOwogIC0ta2RtdnFpZzogY2xhbXAoMi4zNzVyZW0sIGNhbGMoMS4yNDgyNXJlbSArIDIuMzQ3dncpLCAzcmVtKTsKICAtLWtkbXZxaWg6IGNsYW1wKDNyZW0sIGNhbGMoMi4wOTg1NjI1cmVtICsgMS44Nzh2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlpOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS42OTcxODc1cmVtICsgMy43NTZ2dyksIDQuNXJlbSk7CiAgLS1rZG12cWlqOiBjbGFtcCgzLjVyZW0sIGNhbGMoMC43OTU3NXJlbSArIDUuNjM0dncpLCA1cmVtKTsKICAtLWtkbXZxaWs6IGNsYW1wKDVyZW0sIGNhbGMoMC40OTI5Mzc1cmVtICsgOS4zOTB2dyksIDcuNXJlbSk7Cn0%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U3W6jMBCF7%2FsU57KVQuThN8y%2BQN%2Bglyvz08RNgqkhLd3VvnsFSlrGtM1KIIbPZ84MxjY7a3v8vQGCwLqh3aeKEZBS6teMESPIJQoZJEnESCSJGakkyZQlUMqgSKKMId43jCRL22HOckaWbiTTjDwlyQoGUR5LWDIojr3sihFKUjM6ezDVnD16vW0Zap24%2BjiHuxFuMh8bBq0p9PETI1xY7Bkv2t0GwW8yWVgY6u7mwwcGeRnHsejCvBlrRotW7DK%2F9Ss%2Bi4rPyww3VlxYd77PQfj049cuJ%2BE09rmcsheGaofpTtsBblvoW7XC%2BVpTJLxfP9WkrsuHmfw%2F3N%2F4o4%2FwkuenhMnd6sM0%2FkZEifD9M%2F24Tmw4xZNOQvpCGI4skiwaWSxZPK1TyRJGocv91tlTUwWlPVi3wvlRWFfV7gKnXlDrrg5ME9hTL3zSr3x%2BTskYttWl6d%2BuCDeM3umme7TueEWaM3a12e76FY56uL%2BEprmEr6bqd9PgwzkyzTn62Vl7m54KhnekUMnwDhSqeFpXgtWMSI3s3zvXISe%2FewUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA83Y3W7aMBQH8Hue4txM2qQExZivptJeZTKJExv8QR2nQKe%2B%2B9SyNsessplaELfNLz6niXP8F2N%2F8KIppvB7BFBZZV0Jj8x9z%2FPjhSIDaQR30v%2B4HwE01vh8x2UrfOgm2D2Pxse%2Fzl6XfV%2Fr7ZZmptdWFVjOY5JguYjJCZbLmKRY3sXkFEsWkzMsVzE5x7KKyQWWdUwuseQxeYdlE5MMyzYmV1iKmKywlDFZY7mOSY7lJiYbLFVMtljqmBRYmpiUWNqYXGO5jckNlg8xqbB0Mamx7GLSYOlf5QdD4heRi8lKksMwRRqmpTqcgmIAnXzip5fl62UlDc%2FFhwWCj7W%2FdDvrVDvBRHi8dDubVDvB2Nlduh2VaieYbftLt6NT7QQD9HDpdkyqnWBKPw3tfFyNxKv5VLVg2pHik%2BW6VLlgDBPyyXIuVS44H8jkk%2BUeUuWCg4vQVLnETtmmygVnL5mmdm6f2rmJf9%2Bm%2BglSA5mn%2BiHn9DOst%2FiSL3NYb5lab59aL8iS5BgmNXOtNPnKem91CcV4zjVWxyDp%2Bd7n3jHTNdbpEvrtlruKdRzT1TkvdOBVij8GvE7xXcD5OU%2FrnVMQXqsMVrY%2BnPPRoRs776xp%2F7NaI9vecfgJUh9v3cnaixJIUXx7eYlv%2B5X13t6jt6Rstcm5qUsgwWuikHz4Jx30KgN7zJVbVtfStLnijS%2BBjJeVCKiS5%2BwUCoLcVqyiICa3lawoCHpb4YqCSM7h6%2BYrCmJ2WxGLgkieDddNWRS8NGcNqi%2BLWhQ6zZS6at6ioHkte33V1EVBMdfyq0YvCvvkk%2F3S8EVPTrpbCGAvB0gGYpKBoBmIaQZilr19esnDh%2F37y%2BDwU9vfAFPzyjrmpTUl9Kbm7qW1%2B9HzH1Kg5ghdFAAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'tythf04',variantClassNames:{color:{primaryAlt:'tythf05',primary:'tythf06',primaryTintDark:'tythf07',primaryTint:'tythf08',primaryTintLight:'tythf09',primaryTintLighter:'tythf0a',secondaryAlt:'tythf0b',secondary:'tythf0c',secondaryTintDark:'tythf0d',secondaryTint:'tythf0e',secondaryTintLight:'tythf0f',secondaryTintLighter:'tythf0g',orange:'tythf0h',sand:'tythf0i',notifyRed:'tythf0j',notifyGreen:'tythf0k',neutralDark:'tythf0l',neutralLight:'tythf0m',black:'tythf0n',fullBlack:'tythf0o',white:'tythf0p',error:'tythf0q',palePurple:'tythf0r',lightBlue:'tythf0s'},variant:{h1:'tythf0t',h2:'tythf0u',h3:'tythf0v',h4:'tythf0w',h5:'tythf0x',h6:'tythf0y',tiny:'tythf0z',small:'tythf010',medium:'tythf011',large:'tythf012',xl:'tythf013',body:'tythf014',inherit:'tythf015'},bodyFont:{true:'tythf016'},headingFont:{true:'tythf017'},monoFont:{true:'tythf018'},autoMargin:{true:'tythf019'},uppercase:{true:'tythf01a'},weight:{regular:'tythf01b',medium:'tythf01c',semibold:'tythf01d',bold:'tythf01e'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','variant','bodyFont','headingFont','monoFont','autoMargin','uppercase','weight']};
export var txtVars = {color:'var(--tythf00)',display:'var(--tythf01)',fontWeight:'var(--tythf02)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1i)',lineHeight:'var(--_1i72bi14)'},h2:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1j)',lineHeight:'var(--_1i72bi15)'},h3:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1k)',lineHeight:'var(--_1i72bi16)'},h4:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1l)',lineHeight:'var(--_1i72bi17)'},h5:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1m)',lineHeight:'var(--_1i72bi18)'},h6:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1n)',lineHeight:'var(--_1i72bi19)'},tiny:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1t)',lineHeight:'var(--_1i72bi1g)'},small:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1s)',lineHeight:'var(--_1i72bi1f)'},medium:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1r)',lineHeight:'var(--_1i72bi1e)'},large:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1q)',lineHeight:'var(--_1i72bi1d)'},xl:{fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1p)',lineHeight:'var(--_1i72bi1b)'},body:{fontWeight:'var(--_1i72bi1u)',fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1o)',lineHeight:'var(--_1i72bi1a)'},inherit:{}};
export var withHtml = 'tythf03';