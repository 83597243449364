/* eslint-disable @typescript-eslint/no-explicit-any */
import { VENUE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { ADDRESS_BASE_FRAGMENT } from '@/gql/fragments/common.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable, toNumber, TypedDocumentNode } from '@liquorice/utils';
import {
  AccessibilityCategoryFragment,
  EventTypeCategoryFragment,
  GenreCategoryFragment,
  ProgramCategoryFragment,
  VenueCategoryFragment,
} from '__generated__/graphql';
import dayjs from 'dayjs';
import { DisplayRoleMode } from '../parsers/common';
import { EventTime } from '../parsers/common/parseEventTime';

export function calculateDates(
  range: number | null,
  defaultRange: number
): { from: string; to: string } {
  const FromDate = new Date();
  const ToDate = new Date();
  ToDate.setDate(FromDate.getDate() + (range ?? defaultRange));

  return {
    from: fmtSimpleDate(FromDate),
    to: fmtSimpleDate(ToDate),
  };
}

export const fmtRole = (
  role: string,
  displayRoleMode: DisplayRoleMode,
  displayRole?: string | null
) => {
  if (displayRoleMode === 'none') return role;
  if (displayRoleMode === 'appendToRole') return `${role}, ${displayRole}`;
  if (displayRoleMode === 'overwriteRole') return displayRole;
};

type Categories =
  | AccessibilityCategoryFragment
  | EventTypeCategoryFragment
  | GenreCategoryFragment
  | VenueCategoryFragment
  | ProgramCategoryFragment;

export type SimplifiedEntry = {
  title: string;
  id: string;
  uri: string;
};

export const simplifyCategory = (
  fragment: TypedDocumentNode<Categories, unknown>,
  data: MaybeArrayOf<any>
): SimplifiedEntry[] | undefined => {
  const parseCategories = createFragmentArrayParser(fragment, (data) => data);
  const items = parseCategories(data);

  if (!items || items.length <= 0) return undefined;

  return items.map((v) => {
    return {
      title: v.title,
      id: v.id,
      uri: v.uri,
    } as SimplifiedEntry;
  });
};

export const fmtCategoryAddress = (data: MaybeArrayOf<any>) => {
  const parseVenueCategory = createFragmentArrayParser(VENUE_CATEGORY_FRAGMENT, (data) => data);
  const cat = firstNonNullable(parseVenueCategory(data));
  if (!cat || !cat.address) return undefined;

  const parseAddress = createFragmentArrayParser(ADDRESS_BASE_FRAGMENT, (data) => data);
  const address = firstNonNullable(parseAddress(cat.address));

  if (!address) return undefined;

  const {
    addressLine1,
    addressLine2,
    addressLine3,
    administrativeArea,
    countryCode,
    // organization,
    // organizationTaxId,
    postalCode,
    locality,
  } = address ?? {};

  const addressParts: string[] = [];

  if (addressLine1) addressParts.push(addressLine1);
  if (addressLine2) addressParts.push(addressLine2);
  if (addressLine3) addressParts.push(addressLine3);
  if (locality) addressParts.push(locality);
  if (administrativeArea) addressParts.push(administrativeArea);
  if (postalCode) addressParts.push(postalCode);
  if (countryCode) addressParts.push(countryCode);

  return addressParts.filter(Boolean).join(', ');
};

export const fmtSimpleDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const toIsoString = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');
};

export const fmtEventTime = (maybeEventTimes?: (EventTime | null)[]): null | string | undefined => {
  if (!maybeEventTimes) return null;

  const validDates = maybeEventTimes
    .map((event) => event?.date)
    .filter((date): date is string => Boolean(date)) // Ensure date is not undefined
    .map((date) => dayjs(date)) // Convert to dayjs objects
    .filter((date) => date.isValid()) // Remove invalid dates
    .sort((a, b) => a.valueOf() - b.valueOf()); // Sort by ascending order

  if (validDates.length === 0) return null; // No valid dates
  if (validDates.length === 1) return validDates[0].format('ddd DD MMM YYYY'); // Single date case

  const earliest = validDates[0];
  const latest = validDates[validDates.length - 1];

  // Check if the earliest and latest dates are on the same day

  const sameDay = earliest.isSame(latest, 'day');
  const sameMonth = earliest.isSame(latest, 'month');
  const sameYear = earliest.isSame(latest, 'year');

  if (sameYear && sameMonth && !sameDay) {
    return `${earliest.format('ddd DD')} - ${latest.format('DD MMM YYYY')}`;
  } else if (sameYear && !sameDay) {
    return `${earliest.format('ddd DD MMM')} - ${latest.format('ddd DD MMM YYYY')}`;
  } else if (sameYear && sameMonth && sameDay) {
    return `${earliest.format('ddd DD MMM YYYY')}`;
  } else {
    return `${earliest.format('ddd DD MMM YYYY')} - ${latest.format('ddd DD MMM YYYY')}`;
  }
};

export const fmtPercent = (n?: string | number | null) => {
  return `${toNumber(n)}%`;
};

export const fmtMoney = (n?: string | number | null) => {
  return `$${toNumber(n)}`;
};

export const floatPoint = (n: string | number | null) => {
  return toNumber(parseFloat(`${toNumber(n)}`).toPrecision(12));
};

export const roundToTwo = (n: string | number | null) => {
  return Math.round(toNumber(n) * 100) / 100;
};

export const numberedLabel = (n: number | undefined, name: string, nameSingular: string) =>
  (Math.abs(n ?? 0) === 1 ? nameSingular : name).replace('%n', `${n ?? 0}`);

export const removeOrphan = (str: string, charLim = 10) => {
  const lastIndex = str.lastIndexOf(' ');

  if (lastIndex < 1) return str;

  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);

  if (after.length > charLim) return str;

  return (
    <>
      {before}&nbsp;{after}
    </>
  );
};

export const fmtSplitOnCapital = (str: string) => {
  const split = str.split(/(?=[A-Z])/);

  return split.join(' ');
};

export const fmtProperNoun = (str: string): string => {
  return str.replace(/^\w/, (c) => c.toUpperCase());
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const fmtTitleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const fmtFullName = (
  honorific?: string | null,
  firstName?: string | null,
  lastName?: string | null
) => {
  if (!firstName || !lastName) return null;

  return `${honorific ?? ''} ${firstName ?? ''} ${lastName ?? ''}`;
};
