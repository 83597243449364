/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n    mutation AddFaqsToEvent($id: ID, $faqs: [Int]) {\n        save_event_event_Entry(id: $id, faqMultiple: $faqs) {\n            id\n        }\n    }\n": typeof types.AddFaqsToEventDocument,
    "\n  mutation AddProgram($id:ID, $title: String!, $importId: String!) {\n    save_programCategory_Category(id:$id, title: $title, importId: $importId) {\n        id\n        importId\n    }\n  }\n": typeof types.AddProgramDocument,
    "\n  fragment accordionItemBlock on accordionItem_Entry {\n    heading\n    content: htmlContentSimple\n  }\n": typeof types.AccordionItemBlockFragmentDoc,
    "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    accordionItem {\n      ...accordionItemBlock\n    }\n  }\n": typeof types.AccordionBlockFragmentDoc,
    "\n  fragment breakoutBoxBlock on blocks_breakoutBox_BlockType {\n    heading\n    content: htmlContentDefault\n  }\n": typeof types.BreakoutBoxBlockFragmentDoc,
    "\n  fragment buttonBlock on blocks_button_BlockType {\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": typeof types.ButtonBlockFragmentDoc,
    "\n  fragment categoryTypeBlock on blocks_categoryType_BlockType {\n    heading\n  }\n": typeof types.CategoryTypeBlockFragmentDoc,
    "\n  fragment dividerBlock on blocks_divider_BlockType {\n    __typename\n  }\n": typeof types.DividerBlockFragmentDoc,
    "\n  fragment entryCardsBlock on blocks_entryCards_BlockType {\n    heading\n    simpleCardMultiple {\n      ...simpleCardBlock\n      ...externalCardBlock\n    }\n  }\n": typeof types.EntryCardsBlockFragmentDoc,
    "\n  fragment eventCreditBlock on eventCreditItem_Entry {\n    title\n    heading\n  }\n": typeof types.EventCreditBlockFragmentDoc,
    "\n  fragment eventCreditsBlock on blocks_eventCredits_BlockType {\n    heading\n    eventCreditItem {\n      ...eventCreditBlock\n    }\n  }\n": typeof types.EventCreditsBlockFragmentDoc,
    "\n  fragment eventIndex on blocks_eventIndex_BlockType {\n    heading\n\n    # Filters\n    latestEvents\n    dateRange {\n      end\n      isFuture\n      isNotPast\n      isOnGoing\n      isPast\n      start\n    }\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    genreCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    includeEventIndex: lightswitch\n  }\n": typeof types.EventIndexFragmentDoc,
    "\n  fragment eventSearch on blocks_eventSearch_BlockType {\n    heading\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": typeof types.EventSearchFragmentDoc,
    "\n  fragment expandingCardBlock on expandCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    flipAlignment\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n": typeof types.ExpandingCardBlockFragmentDoc,
    "\n  fragment expandingCardsBlock on blocks_expandingCards_BlockType {\n    expandCardMultiple {\n      ...expandingCardBlock\n      ...externalCardBlock\n    }\n  }\n": typeof types.ExpandingCardsBlockFragmentDoc,
    "\n  fragment externalCardBlock on externalCard_Entry {  \n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": typeof types.ExternalCardBlockFragmentDoc,
    "\n  fragment faqCardsBlock on blocks_faqCards_BlockType {\n    heading\n    faqMultiple {\n      ...faqCard\n    }\n  }\n": typeof types.FaqCardsBlockFragmentDoc,
    "\n  fragment featuredEvent on blocks_featuredEvent_BlockType {\n    eventSingle {\n      ...eventCard\n    }\n  }\n": typeof types.FeaturedEventFragmentDoc,
    "\n  fragment fileDownloadBlock on blocks_fileDownload_BlockType {\n    heading\n    fileMultiple {\n      ...document\n    }\n  }\n": typeof types.FileDownloadBlockFragmentDoc,
    "\n  fragment formBlock on blocks_form_BlockType {\n    heading\n    content: htmlContentSimple\n    form {\n      id\n    }\n  }\n": typeof types.FormBlockFragmentDoc,
    "\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n    flipAlignment\n    imageSingle {\n      ...image\n    }\n    lightswitch\n  }\n": typeof types.ImageAndTextBlockFragmentDoc,
    "\n  fragment imageBlock on blocks_image_BlockType {\n    imageSingle {\n      ...image\n    }\n    caption\n  }\n": typeof types.ImageBlockFragmentDoc,
    "\n  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {\n    imageMultiple {\n      ...image\n    }\n  }\n": typeof types.ImageCarouselBlockFragmentDoc,
    "\n  fragment logoAndTextItemBlock on logoAndTextItem_Entry {\n    title\n    imageMultiple {\n      ...image\n    }\n    lightswitch\n  }  \n": typeof types.LogoAndTextItemBlockFragmentDoc,
    "\n  fragment logoAndTextBlock on blocks_logoAndText_BlockType {\n    logoAndTextItem {\n      ...logoAndTextItemBlock\n    }\n  }\n": typeof types.LogoAndTextBlockFragmentDoc,
    "\n  fragment overviewCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    imageSingle {\n      ...image\n    }\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n": typeof types.OverviewCardBlockFragmentDoc,
    "\n  fragment overviewCardsBlock on blocks_overviewCards_BlockType {\n    overviewCardMultiple {\n      ...overviewCardBlock\n      ...externalCardBlock\n    }\n  }\n": typeof types.OverviewCardsBlockFragmentDoc,
    "\n  fragment quoteBlock on quoteItem_Entry {\n    heading \n    label\n  }\n": typeof types.QuoteBlockFragmentDoc,
    "\n  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {\n    quoteItem {\n      ...quoteBlock\n    }\n  }\n": typeof types.QuoteCarouselBlockFragmentDoc,
    "\n  fragment relatedEventsBlock on blocks_relatedEvents_BlockType {\n    eventMultiple {\n      ...eventCard\n    }\n    heading\n  }\n": typeof types.RelatedEventsBlockFragmentDoc,
    "\n  fragment relatedNewsBlock on blocks_relatedNews_BlockType {\n    articleMultiple {\n      ...articleCard\n    }\n    heading\n  }\n": typeof types.RelatedNewsBlockFragmentDoc,
    "\n  fragment richTextBlock on blocks_richText_BlockType {\n    heading\n    content: htmlContentDefault\n    textColorScheme {\n      label\n    }\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": typeof types.RichTextBlockFragmentDoc,
    "\n  fragment simpleCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n": typeof types.SimpleCardBlockFragmentDoc,
    "\n  fragment tableBlock on blocks_table_BlockType {\n    id\n    table {\n      ...table\n    }\n  }\n": typeof types.TableBlockFragmentDoc,
    "\n  fragment videoBlock on blocks_video_BlockType {\n    videoUrl\n  }\n": typeof types.VideoBlockFragmentDoc,
    "\n  fragment image on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      id\n      mimeType\n      title\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n": typeof types.ImageFragmentDoc,
    "\n    fragment column on column_Entry {\n        id\n        content: htmlContentSimple\n    }\n": typeof types.ColumnFragmentDoc,
    "\n    fragment row on row_Entry {\n        id\n        headingRow: lightswitch\n        column {\n            ...column\n        }\n    }\n": typeof types.RowFragmentDoc,
    "\n    fragment table on table_Entry {\n        id\n        row {\n            ...row\n        }\n    }\n": typeof types.TableFragmentDoc,
    "\n  fragment blockBase on NeoBlockInterface {\n    id\n    __typename\n    level\n  }\n": typeof types.BlockBaseFragmentDoc,
    "\n  fragment blocks on blocks_NeoField {\n    ...blockBase\n    ...richTextBlock\n    ...buttonBlock\n    ...accordionBlock\n    ...quoteCarouselBlock\n    ...dividerBlock\n    ...breakoutBoxBlock\n    ...tableBlock\n\n    # Event fragments\n    ...categoryTypeBlock\n    ...eventIndex\n    ...eventSearch\n    ...featuredEvent\n    ...eventCreditsBlock\n\n    # Media fragments\n    ...imageAndTextBlock\n    ...logoAndTextBlock\n    ...imageBlock\n    ...imageCarouselBlock\n    ...videoBlock\n    ...fileDownloadBlock\n    ...formBlock\n\n    # Related\n    ...relatedNewsBlock\n    ...relatedEventsBlock\n\n    # Cards\n    ...entryCardsBlock\n    ...expandingCardsBlock\n    ...overviewCardsBlock\n    ...faqCardsBlock\n  }\n": typeof types.BlocksFragmentDoc,
    "\n  fragment categoryId on CategoryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n    }\n  }\n": typeof types.CategoryIdFragmentDoc,
    "\n  fragment accessibilityCategory on accessibilityCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": typeof types.AccessibilityCategoryFragmentDoc,
    "\n  fragment eventTypeCategory on eventTypeCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": typeof types.EventTypeCategoryFragmentDoc,
    "\n  fragment genreCategory on genreCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    imageSingle {\n      ...image\n    }\n  }\n": typeof types.GenreCategoryFragmentDoc,
    "\n  fragment venueCategory on venueCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    address {\n      ...address\n    }\n    venueSingle {\n      ...venueCard\n    }\n    venueShorthand\n    bookingUrl: externalUrl\n    capacity: htmlContentSimple\n    venueInfo {\n      ...eventInfoCard\n    }\n  }\n": typeof types.VenueCategoryFragmentDoc,
    "\n  fragment programCategory on programCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    colorScheme {\n      label\n    }\n  }\n": typeof types.ProgramCategoryFragmentDoc,
    "\n  fragment faqCategory on faqCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": typeof types.FaqCategoryFragmentDoc,
    "\n  fragment eventLabelCategory on eventLabelCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": typeof types.EventLabelCategoryFragmentDoc,
    "\n  fragment categories on CategoryInterface {\n    ...accessibilityCategory\n    ...eventTypeCategory\n    ...genreCategory\n    ...venueCategory\n    ...programCategory\n    ...faqCategory\n    ...eventLabelCategory\n  }\n": typeof types.CategoriesFragmentDoc,
    "\n  fragment socialLinks on socialLinks_Entry {\n    __typename\n    facebook\n    twitter\n    instagram\n  }\n": typeof types.SocialLinksFragmentDoc,
    "\n  fragment address on Address {\n    __typename\n    addressLine1\n    addressLine2\n    addressLine3\n    administrativeArea\n    countryCode\n    organization\n    organizationTaxId\n    postalCode\n    sortingCode\n    latitude\n    locality\n    longitude\n    language\n    dependentLocality\n  }\n": typeof types.AddressFragmentDoc,
    "\n  fragment commonLink on EntryInterface {\n    id\n    uri\n    title\n  }\n": typeof types.CommonLinkFragmentDoc,
    "\n    query commonLinks($site: [String] = \"default\") {\n      home: entry(site: $site, uri: \"__home__\") {\n        ...commonLink\n      }\n      search: entry(site: $site, section: \"search\") {\n        ...commonLink\n      }\n      articleIndex: entry(site: $site, section: \"articleIndex\") {\n        ...commonLink\n      }\n      eventIndex: entry(site: $site, section: \"page\", type: \"index\") {\n        ...commonLink\n      }\n  }\n": typeof types.CommonLinksDocument,
    "\n  fragment document on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n": typeof types.DocumentFragmentDoc,
    "\n  fragment siblingBranch on EntryInterface {\n    children {\n      title\n      uri\n      id\n      level\n      children {\n        title\n        uri\n        id\n        level\n        children {\n          title\n          uri\n          id\n          level\n        }\n    }\n    }\n  }\n": typeof types.SiblingBranchFragmentDoc,
    "\n  fragment entryId on EntryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n      title\n    }\n  }\n": typeof types.EntryIdFragmentDoc,
    "\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    title\n    sectionHandle\n    status\n    enabled\n    postDate @formatDateTime(format: \"j M, Y\")\n    parent {\n      id\n      uri\n      title\n    }\n  }\n": typeof types.EntryBaseFragmentDoc,
    "\n  fragment articleIndexEntry on index_Entry {\n    ...articleIndexCard\n    articleSingle {\n      ...articleCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": typeof types.ArticleIndexEntryFragmentDoc,
    "\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n    eventMultiple {\n      ...eventCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": typeof types.ArticleEntryFragmentDoc,
    "\n  fragment homeEntry on home_Entry {\n    ...homeCard\n    blocks {\n      ...blocks\n    }\n  }\n": typeof types.HomeEntryFragmentDoc,
    "\n  fragment venueEntry on venue_Entry {\n    ...venueCard\n    blocks {\n      ...blocks\n    }\n    venueCategory {\n      ...categories\n    }\n  }\n": typeof types.VenueEntryFragmentDoc,
    "\n  fragment pageStandardEntry on standard_Entry {\n    ...pageStandardCard\n    typeHandle\n    siblingBranch: parent {\n      ...siblingBranch\n    }\n    ancestors {\n      id\n      title\n      uri\n      parent {\n        id\n      }\n    }\n    blocks {\n      ...blocks\n    }\n  }\n": typeof types.PageStandardEntryFragmentDoc,
    "\n  fragment pageOverviewEntry on overview_Entry {\n    ...pageOverviewCard\n    blocks {\n      ...blocks\n    }\n  }\n": typeof types.PageOverviewEntryFragmentDoc,
    "\n  fragment pageExpandableEntry on expandable_Entry {\n    ...pageExpandableCard\n    typeHandle\n    blocks {\n      ...blocks\n    }\n  }\n": typeof types.PageExpandableEntryFragmentDoc,
    "\n  fragment shortlistEntry on index_Entry {\n    ...shortlistCard\n  }\n": typeof types.ShortlistEntryFragmentDoc,
    "\n  fragment searchEntry on index_Entry {\n    ...searchCard\n  }\n": typeof types.SearchEntryFragmentDoc,
    "\n  fragment notFoundEntry on notFound_Entry {\n    ...notFoundCard\n  }\n": typeof types.NotFoundEntryFragmentDoc,
    "\n  fragment eventIndexEntry on index_Entry {\n    ...eventIndexCard\n  }\n": typeof types.EventIndexEntryFragmentDoc,
    "\n  fragment eventEntry on event_Entry {\n    ...eventCard\n    status\n    blocks {\n      ...blocks\n    }\n    onSale\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    eventLabelCategory {\n      ...eventLabelCategory\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    eventPrice {\n      ...eventPriceCard\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    eventInfo {\n      ...eventInfoCard\n    }\n    faqMultiple {\n      ...faqCard\n    }\n  }\n": typeof types.EventEntryFragmentDoc,
    "\n  fragment faqIndexEntry on faqIndex_Entry {\n    ...faqIndexCard\n  }\n": typeof types.FaqIndexEntryFragmentDoc,
    "\n  fragment eventInfoEntry on eventInfo_Entry {\n    ...eventInfoCard\n  }\n": typeof types.EventInfoEntryFragmentDoc,
    "\n  fragment eventPriceEntry on eventPrice_Entry {\n    ...eventPriceCard\n  }\n": typeof types.EventPriceEntryFragmentDoc,
    "\n  fragment eventTimeEntry on eventTime_Entry {\n    ...eventTimeCard\n  }\n": typeof types.EventTimeEntryFragmentDoc,
    "\n  fragment entries on EntryInterface {\n    ...entryBase\n    ...entrySeo\n    ...articleIndexEntry\n    ...articleEntry\n    ...homeEntry\n    ...venueEntry\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...pageExpandableEntry\n    ...shortlistEntry\n    ...searchEntry\n    ...notFoundEntry\n\n    ...eventIndexEntry\n    ...eventEntry\n    \n    ...faqIndexEntry\n    # ...faqEntry\n\n    # Event matrix entries\n    # ...eventInfoEntry\n    # ...eventPriceEntry\n    # ...eventTimeEntry\n  }\n": typeof types.EntriesFragmentDoc,
    "\n  fragment articleIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": typeof types.ArticleIndexCardFragmentDoc,
    "\n  fragment articleCard on article_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": typeof types.ArticleCardFragmentDoc,
    "\n  fragment homeCard on home_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n\n    # Hero section\n    eventMultiple {\n      ...eventCard\n    }\n  }\n": typeof types.HomeCardFragmentDoc,
    "\n  fragment venueCard on venue_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": typeof types.VenueCardFragmentDoc,
    "\n  fragment pageStandardCard on standard_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": typeof types.PageStandardCardFragmentDoc,
    "\n  fragment pageOverviewCard on overview_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n": typeof types.PageOverviewCardFragmentDoc,
    "\n  fragment pageExpandableCard on expandable_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": typeof types.PageExpandableCardFragmentDoc,
    "\n  fragment shortlistCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": typeof types.ShortlistCardFragmentDoc,
    "\n  fragment searchCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": typeof types.SearchCardFragmentDoc,
    "\n  fragment notFoundCard on notFound_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": typeof types.NotFoundCardFragmentDoc,
    "\n  fragment faqCard on faq_Entry {\n    ...entryBase\n    heading\n    content: htmlContentDefault\n    faqCategory {\n      ...faqCategory\n    }\n  }\n": typeof types.FaqCardFragmentDoc,
    "\n  fragment faqIndexCard on faqIndex_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": typeof types.FaqIndexCardFragmentDoc,
    "\n  fragment eventIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": typeof types.EventIndexCardFragmentDoc,
    "\n  fragment eventCard on event_Entry {\n    ...entryBase\n    status\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    videoUrl\n    genreCategory {\n      ...genreCategory\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n": typeof types.EventCardFragmentDoc,
    "\n  fragment eventPriceCard on eventPrice_Entry {\n    ...entryBase\n    ownerId\n    title\n    minPrice\n    maxPrice\n  }\n": typeof types.EventPriceCardFragmentDoc,
    "\n  fragment eventTimeCard on eventTime_Entry {\n    ...entryBase\n    ownerId\n    date\n    eventTimeId\n  }\n": typeof types.EventTimeCardFragmentDoc,
    "\n  fragment eventInfoCard on eventInfo_Entry {\n    ...entryBase\n    heading\n    content: htmlContentSimple\n  }\n": typeof types.EventInfoCardFragmentDoc,
    "\n  fragment entryCards on EntryInterface {\n    ...articleIndexCard\n    ...articleCard\n    ...homeCard  \n    ...venueCard\n    ...pageStandardCard\n    ...pageOverviewCard\n    ...pageExpandableCard\n    ...shortlistCard\n    ...searchCard\n    ...notFoundCard\n    ...eventIndexCard\n    ...eventCard\n\n    ...faqCard\n    # ...eventPriceCard\n    # ...eventTimeCard\n    # ...eventInfoCard\n  }\n": typeof types.EntryCardsFragmentDoc,
    "\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n": typeof types.GlobalCopyrightFragmentDoc,
    "\n  fragment globalCompanyInformation on companyInformation_GlobalSet {\n    content: htmlContentDefault\n    __typename\n    id\n    handle\n  }\n": typeof types.GlobalCompanyInformationFragmentDoc,
    "\n  fragment globalPricingInformation on pricingInformation_GlobalSet {\n    content: htmlContentSimple\n    __typename\n    id\n    handle\n  }\n": typeof types.GlobalPricingInformationFragmentDoc,
    "\n  fragment globalShoppingBasket on shoppingBasket_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n": typeof types.GlobalShoppingBasketFragmentDoc,
    "\n  fragment globalProfile on profile_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n": typeof types.GlobalProfileFragmentDoc,
    "\n  fragment globalSocialLinks on socialLinks_GlobalSet {\n    socialLinks {\n      ...socialLinks\n    }\n    __typename\n    id\n    handle\n  }\n": typeof types.GlobalSocialLinksFragmentDoc,
    "\n  fragment globalPresenterServicesDepartment on presenterServicesDepartment_GlobalSet {\n    label\n    phoneNumber {\n      number\n    }\n    email\n    __typename\n    id\n    handle\n  }\n": typeof types.GlobalPresenterServicesDepartmentFragmentDoc,
    "\n  fragment globals on GlobalSetInterface {\n    ...globalCopyright\n    ...globalCompanyInformation\n    ...globalSocialLinks\n    ...globalShoppingBasket\n    ...globalProfile\n    ...globalPresenterServicesDepartment\n    ...globalPricingInformation\n  }\n": typeof types.GlobalsFragmentDoc,
    "\n  fragment asset on assetsVolume_Asset {\n    id\n    uid\n    url\n    height\n    width\n    alt\n    mimeType\n    title\n  }\n": typeof types.AssetFragmentDoc,
    "\n  fragment imageAsset on AssetInterface {\n    ...asset\n  }\n": typeof types.ImageAssetFragmentDoc,
    "\n  fragment linkFieldUrl on linkField_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldUrlFragmentDoc,
    "\n  fragment linkFieldEntry on linkField_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldEntryFragmentDoc,
    "\n  fragment linkFieldCustom on linkField_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldCustomFragmentDoc,
    "\n  fragment linkFieldEmail on linkField_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldEmailFragmentDoc,
    "\n  fragment linkFieldPhone on linkField_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldPhoneFragmentDoc,
    "\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldCustom\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n": typeof types.LinkFieldFragmentDoc,
    "\n  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldMultipleUrlFragmentDoc,
    "\n  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldMultipleEntryFragmentDoc,
    "\n  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldMultipleCustomFragmentDoc,
    "\n  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldMultipleEmailFragmentDoc,
    "\n  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": typeof types.LinkFieldMultiplePhoneFragmentDoc,
    "\n  fragment linkFieldMultiple on HyperLinkInterface {\n    ...linkFieldMultipleUrl\n    ...linkFieldMultipleEntry\n    ...linkFieldMultipleCustom\n    ...linkFieldMultipleEmail\n    ...linkFieldMultiplePhone\n  }\n": typeof types.LinkFieldMultipleFragmentDoc,
    "\n  fragment navigationNode on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n      uri\n      language\n    }\n  }\n": typeof types.NavigationNodeFragmentDoc,
    "\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n": typeof types.SeoMetaLinksFragmentDoc,
    "\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n": typeof types.SeoMetaTagsFragmentDoc,
    "\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n": typeof types.SeoTitleFragmentDoc,
    "\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n": typeof types.SeoScriptsFragmentDoc,
    "\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n": typeof types.SeoJsonLdFragmentDoc,
    "\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n": typeof types.SeoSiteVarsFragmentDoc,
    "\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      metaTitleContainer\n      metaJsonLdContainer\n      metaLinkContainer\n      metaTagContainer\n      metaScriptContainer\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      metaSiteVarsContainer\n    }\n  }\n": typeof types.EntrySeoFragmentDoc,
    "\n    mutation ArticleMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_article_article_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": typeof types.ArticleMutationDocument,
    "\n    mutation EventMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String,\n        $entrySubTitle: String,\n        $videoUrl: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $eventId: String\n        # $appendTo: ID\n        ) {\n        save_event_event_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            eventId: $eventId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySubTitle: $entrySubTitle\n            videoUrl: $videoUrl\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": typeof types.EventMutationDocument,
    "\n    mutation OverviewMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        $programCategory: [Int]\n        # $appendTo: ID\n        ) {\n        save_page_overview_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            programCategory: $programCategory\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": typeof types.OverviewMutationDocument,
    "\n    mutation StandardMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_standard_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": typeof types.StandardMutationDocument,
    "\n    mutation VenueMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_venue_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": typeof types.VenueMutationDocument,
    "\n  query Assets {\n    assets(volume: [\"assetsVolume\", \"documentsVolume\"]) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }    \n": typeof types.AssetsDocument,
    "\n  query categories {\n    accessibilityCategory: categories(group: \"accessibilityCategory\") {\n      ...categories\n    }\n    eventTypeCategory: categories(group: \"eventTypeCategory\") {\n      ...categories\n    }\n    genreCategory: categories(group: \"genreCategory\") {\n      ...categories\n    }\n    locationCategory: categories(group: \"locationCategory\") {\n      ...categories\n    }\n    programCategory: categories(group: \"programCategory\") {\n      ...categories\n    }\n  }\n": typeof types.CategoriesDocument,
    "\n  query category($group: [String]) {\n    category: categories(group: $group) {\n      ...categories\n    }\n  }\n": typeof types.CategoryDocument,
    "\n  query listEntries(\n    $site: [String] = \"default\",\n    $limit: Int,\n    $section: [String],\n    $type: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n    $relatedToCategories: [CategoryRelationCriteriaInput] = null\n  ) {\n    entries(\n      site: $site,\n      relatedToEntries: $relatedToEntries\n      relatedToCategories: $relatedToCategories\n      limit: $limit\n      section: $section\n      orderBy: $orderBy,\n      type: $type\n    ) {\n      ...entryId\n    }\n  }\n": typeof types.ListEntriesDocument,
    "\n  query listCategories(\n    $site: [String] = \"default\",\n    $group: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n  ) {\n    categories(\n      site: $site,\n      group: $group,\n      orderBy: $orderBy,\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...categoryId\n    }\n  }\n": typeof types.ListCategoriesDocument,
    "\n  query searchEntries(\n      $site: [String] = \"default\",\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput],\n      $fixedOrder: Boolean\n      $id: [QueryArgument]\n    ) {\n    entries(\n      site: $site,\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      fixedOrder: $fixedOrder\n      id: $id\n    ) {\n      ...entryCards\n    }\n    entryCount(\n      site: $site,\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      id: $id\n    )\n  }\n": typeof types.SearchEntriesDocument,
    "\n    query eventPriceEntries {\n        eventPriceFieldEntries {\n            ...eventPriceCard\n        } \n    }\n": typeof types.EventPriceEntriesDocument,
    "\n    query eventTimeEntries($date: [QueryArgument]) {\n        eventTimeFieldEntries(date: $date, orderBy: \"date ASC\") {\n            ...eventTimeCard\n        } \n    }\n": typeof types.EventTimeEntriesDocument,
    "\n    query eventIndexEntries(\n        $genreCategory: [QueryArgument],\n        $venueCategory: [QueryArgument],\n        $accessibilityCategory: [QueryArgument],\n        $programCategory: [QueryArgument],\n        $eventTypeCategory: [QueryArgument],\n        $id: [QueryArgument],\n        $orderBy: String\n      ) {\n        eventEntries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {\n            ...eventCard\n        }\n    }\n": typeof types.EventIndexEntriesDocument,
    "\n  query formExists($uid: [String]!) {\n    formieForm(uid: $uid) {\n      id\n      handle\n      submissionMutationName\n    }\n  }\n": typeof types.FormExistsDocument,
    "\n  query globals($handle: [String]) {\n    globalSets(handle: $handle) {\n      ...globals\n    }\n  }\n": typeof types.GlobalsDocument,
    "\n  query navMenus {\n    primaryNav: navigationNodes(navHandle: \"primaryNav\") {\n      ...navigationNode\n    }\n    secondaryNav: navigationNodes(navHandle: \"secondaryNav\") {\n      ...navigationNode\n    }\n    footerNav: navigationNodes(navHandle: \"footerNav\") {\n      ...navigationNode\n    }\n    footerExtrasNav: navigationNodes(navHandle: \"footerExtrasNav\") {\n      ...navigationNode\n    }\n    auxiliaryNav: navigationNodes(navHandle: \"auxiliaryNav\") {\n      ...navigationNode\n    }\n    policiesNav: navigationNodes(navHandle: \"policiesNav\") {\n      ...navigationNode\n    }\n  }\n": typeof types.NavMenusDocument,
    "\n  query navMenu($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      ...navigationNode\n    }\n  }\n": typeof types.NavMenuDocument,
    "\n  query allUris(\n    $section: [String], \n    $type: [String], \n    $orderBy: String, \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entries(section: $section, type: $type, orderBy: $orderBy, status: $status) {\n      id\n      uri\n      slug\n    }\n  }\n": typeof types.AllUrisDocument,
    "\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    $status: [String] = [\"live\"]\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy, status: $status) {\n      ...entries\n    }\n  }\n": typeof types.ContentByUriDocument,
    "\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $descendantOf: Int\n    $descendantDist: Int\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, descendantOf: $descendantOf, descendantDist: $descendantDist, status: $status) {\n      ...entries\n    }\n  }\n": typeof types.ContentByTypeDocument,
    "\n  query entryExists(\n    $uri: [String], \n    $slug: [String], \n    $id: [QueryArgument], \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entry(uri: $uri, id: $id, slug: $slug, status: $status, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n": typeof types.EntryExistsDocument,
    "\n  query relatedEntries(\n    $site: [String] = \"default\"\n    $exclude: QueryArgument\n    $section: [String]\n    $categoryId: [QueryArgument]\n    $parentId: Int\n    $orderBy: String\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(\n      site: $site\n      limit: 4\n      id: [\"not\", $exclude]\n      relatedTo: $categoryId\n      section: $section\n      orderBy: $orderBy\n      descendantOf: $parentId\n      status: $status\n    ) {\n      ...entryCards\n    }\n  }\n": typeof types.RelatedEntriesDocument,
    "\n  query allImportedEvents(\n    $orderBy: String, \n    $status: [String]\n    ) {\n    eventEntries(orderBy: $orderBy, status: $status) {\n      ... on event_Entry {\n        title\n        eventId\n      }\n    }\n  }\n": typeof types.AllImportedEventsDocument,
    "\n  query AssetByImportId($importId: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }\n": typeof types.AssetByImportIdDocument,
    "\n  query AssetCheck($importId: [QueryArgument], $id: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, id: $id, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n        url\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n        url\n      }\n    }\n  }\n": typeof types.AssetCheckDocument,
    "\n  query CategoryByImportId($importId: [QueryArgument], $group: [String]) {\n    category(importId: $importId, group: $group) {\n      ... on venueCategory_Category {\n      id\n    }\n    ... on programCategory_Category {\n      id\n      importId\n    }\n    ... on genreCategory_Category {\n      id\n    }\n    ... on faqCategory_Category {\n      id\n    }\n    ... on eventTypeCategory_Category {\n      id\n    }\n    ... on eventLabelCategory_Category {\n      id\n    }\n    ... on accessibilityCategory_Category {\n      id\n    }\n    }\n  }\n": typeof types.CategoryByImportIdDocument,
    "\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n    $status: [String]\n    $id: [QueryArgument]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    status: $status\n    id: $id\n    ) {\n      ...entries\n  }\n}\n": typeof types.EntryDocument,
    "\n  query EntryByImportId($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n      ... on venue_Entry {\n        id\n        importId\n      }\n    }\n  }\n": typeof types.EntryByImportIdDocument,
    "\n  query EntryCheck($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n    }\n  }\n": typeof types.EntryCheckDocument,
    "\n  query Events($eventId: [QueryArgument], $type: [String], $id: [QueryArgument]) {\n    eventEntries(eventId: $eventId, type: $type, id: $id, status: [\"enabled\", \"disabled\"]) {\n      ... on event_Entry {\n        id\n        eventId\n      }\n    }\n  }\n": typeof types.EventsDocument,
};
const documents: Documents = {
    "\n    mutation AddFaqsToEvent($id: ID, $faqs: [Int]) {\n        save_event_event_Entry(id: $id, faqMultiple: $faqs) {\n            id\n        }\n    }\n": types.AddFaqsToEventDocument,
    "\n  mutation AddProgram($id:ID, $title: String!, $importId: String!) {\n    save_programCategory_Category(id:$id, title: $title, importId: $importId) {\n        id\n        importId\n    }\n  }\n": types.AddProgramDocument,
    "\n  fragment accordionItemBlock on accordionItem_Entry {\n    heading\n    content: htmlContentSimple\n  }\n": types.AccordionItemBlockFragmentDoc,
    "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    accordionItem {\n      ...accordionItemBlock\n    }\n  }\n": types.AccordionBlockFragmentDoc,
    "\n  fragment breakoutBoxBlock on blocks_breakoutBox_BlockType {\n    heading\n    content: htmlContentDefault\n  }\n": types.BreakoutBoxBlockFragmentDoc,
    "\n  fragment buttonBlock on blocks_button_BlockType {\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": types.ButtonBlockFragmentDoc,
    "\n  fragment categoryTypeBlock on blocks_categoryType_BlockType {\n    heading\n  }\n": types.CategoryTypeBlockFragmentDoc,
    "\n  fragment dividerBlock on blocks_divider_BlockType {\n    __typename\n  }\n": types.DividerBlockFragmentDoc,
    "\n  fragment entryCardsBlock on blocks_entryCards_BlockType {\n    heading\n    simpleCardMultiple {\n      ...simpleCardBlock\n      ...externalCardBlock\n    }\n  }\n": types.EntryCardsBlockFragmentDoc,
    "\n  fragment eventCreditBlock on eventCreditItem_Entry {\n    title\n    heading\n  }\n": types.EventCreditBlockFragmentDoc,
    "\n  fragment eventCreditsBlock on blocks_eventCredits_BlockType {\n    heading\n    eventCreditItem {\n      ...eventCreditBlock\n    }\n  }\n": types.EventCreditsBlockFragmentDoc,
    "\n  fragment eventIndex on blocks_eventIndex_BlockType {\n    heading\n\n    # Filters\n    latestEvents\n    dateRange {\n      end\n      isFuture\n      isNotPast\n      isOnGoing\n      isPast\n      start\n    }\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    genreCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    includeEventIndex: lightswitch\n  }\n": types.EventIndexFragmentDoc,
    "\n  fragment eventSearch on blocks_eventSearch_BlockType {\n    heading\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": types.EventSearchFragmentDoc,
    "\n  fragment expandingCardBlock on expandCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    flipAlignment\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n": types.ExpandingCardBlockFragmentDoc,
    "\n  fragment expandingCardsBlock on blocks_expandingCards_BlockType {\n    expandCardMultiple {\n      ...expandingCardBlock\n      ...externalCardBlock\n    }\n  }\n": types.ExpandingCardsBlockFragmentDoc,
    "\n  fragment externalCardBlock on externalCard_Entry {  \n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.ExternalCardBlockFragmentDoc,
    "\n  fragment faqCardsBlock on blocks_faqCards_BlockType {\n    heading\n    faqMultiple {\n      ...faqCard\n    }\n  }\n": types.FaqCardsBlockFragmentDoc,
    "\n  fragment featuredEvent on blocks_featuredEvent_BlockType {\n    eventSingle {\n      ...eventCard\n    }\n  }\n": types.FeaturedEventFragmentDoc,
    "\n  fragment fileDownloadBlock on blocks_fileDownload_BlockType {\n    heading\n    fileMultiple {\n      ...document\n    }\n  }\n": types.FileDownloadBlockFragmentDoc,
    "\n  fragment formBlock on blocks_form_BlockType {\n    heading\n    content: htmlContentSimple\n    form {\n      id\n    }\n  }\n": types.FormBlockFragmentDoc,
    "\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n    flipAlignment\n    imageSingle {\n      ...image\n    }\n    lightswitch\n  }\n": types.ImageAndTextBlockFragmentDoc,
    "\n  fragment imageBlock on blocks_image_BlockType {\n    imageSingle {\n      ...image\n    }\n    caption\n  }\n": types.ImageBlockFragmentDoc,
    "\n  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {\n    imageMultiple {\n      ...image\n    }\n  }\n": types.ImageCarouselBlockFragmentDoc,
    "\n  fragment logoAndTextItemBlock on logoAndTextItem_Entry {\n    title\n    imageMultiple {\n      ...image\n    }\n    lightswitch\n  }  \n": types.LogoAndTextItemBlockFragmentDoc,
    "\n  fragment logoAndTextBlock on blocks_logoAndText_BlockType {\n    logoAndTextItem {\n      ...logoAndTextItemBlock\n    }\n  }\n": types.LogoAndTextBlockFragmentDoc,
    "\n  fragment overviewCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    imageSingle {\n      ...image\n    }\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n": types.OverviewCardBlockFragmentDoc,
    "\n  fragment overviewCardsBlock on blocks_overviewCards_BlockType {\n    overviewCardMultiple {\n      ...overviewCardBlock\n      ...externalCardBlock\n    }\n  }\n": types.OverviewCardsBlockFragmentDoc,
    "\n  fragment quoteBlock on quoteItem_Entry {\n    heading \n    label\n  }\n": types.QuoteBlockFragmentDoc,
    "\n  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {\n    quoteItem {\n      ...quoteBlock\n    }\n  }\n": types.QuoteCarouselBlockFragmentDoc,
    "\n  fragment relatedEventsBlock on blocks_relatedEvents_BlockType {\n    eventMultiple {\n      ...eventCard\n    }\n    heading\n  }\n": types.RelatedEventsBlockFragmentDoc,
    "\n  fragment relatedNewsBlock on blocks_relatedNews_BlockType {\n    articleMultiple {\n      ...articleCard\n    }\n    heading\n  }\n": types.RelatedNewsBlockFragmentDoc,
    "\n  fragment richTextBlock on blocks_richText_BlockType {\n    heading\n    content: htmlContentDefault\n    textColorScheme {\n      label\n    }\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": types.RichTextBlockFragmentDoc,
    "\n  fragment simpleCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n": types.SimpleCardBlockFragmentDoc,
    "\n  fragment tableBlock on blocks_table_BlockType {\n    id\n    table {\n      ...table\n    }\n  }\n": types.TableBlockFragmentDoc,
    "\n  fragment videoBlock on blocks_video_BlockType {\n    videoUrl\n  }\n": types.VideoBlockFragmentDoc,
    "\n  fragment image on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      id\n      mimeType\n      title\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n": types.ImageFragmentDoc,
    "\n    fragment column on column_Entry {\n        id\n        content: htmlContentSimple\n    }\n": types.ColumnFragmentDoc,
    "\n    fragment row on row_Entry {\n        id\n        headingRow: lightswitch\n        column {\n            ...column\n        }\n    }\n": types.RowFragmentDoc,
    "\n    fragment table on table_Entry {\n        id\n        row {\n            ...row\n        }\n    }\n": types.TableFragmentDoc,
    "\n  fragment blockBase on NeoBlockInterface {\n    id\n    __typename\n    level\n  }\n": types.BlockBaseFragmentDoc,
    "\n  fragment blocks on blocks_NeoField {\n    ...blockBase\n    ...richTextBlock\n    ...buttonBlock\n    ...accordionBlock\n    ...quoteCarouselBlock\n    ...dividerBlock\n    ...breakoutBoxBlock\n    ...tableBlock\n\n    # Event fragments\n    ...categoryTypeBlock\n    ...eventIndex\n    ...eventSearch\n    ...featuredEvent\n    ...eventCreditsBlock\n\n    # Media fragments\n    ...imageAndTextBlock\n    ...logoAndTextBlock\n    ...imageBlock\n    ...imageCarouselBlock\n    ...videoBlock\n    ...fileDownloadBlock\n    ...formBlock\n\n    # Related\n    ...relatedNewsBlock\n    ...relatedEventsBlock\n\n    # Cards\n    ...entryCardsBlock\n    ...expandingCardsBlock\n    ...overviewCardsBlock\n    ...faqCardsBlock\n  }\n": types.BlocksFragmentDoc,
    "\n  fragment categoryId on CategoryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n    }\n  }\n": types.CategoryIdFragmentDoc,
    "\n  fragment accessibilityCategory on accessibilityCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": types.AccessibilityCategoryFragmentDoc,
    "\n  fragment eventTypeCategory on eventTypeCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": types.EventTypeCategoryFragmentDoc,
    "\n  fragment genreCategory on genreCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    imageSingle {\n      ...image\n    }\n  }\n": types.GenreCategoryFragmentDoc,
    "\n  fragment venueCategory on venueCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    address {\n      ...address\n    }\n    venueSingle {\n      ...venueCard\n    }\n    venueShorthand\n    bookingUrl: externalUrl\n    capacity: htmlContentSimple\n    venueInfo {\n      ...eventInfoCard\n    }\n  }\n": types.VenueCategoryFragmentDoc,
    "\n  fragment programCategory on programCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    colorScheme {\n      label\n    }\n  }\n": types.ProgramCategoryFragmentDoc,
    "\n  fragment faqCategory on faqCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": types.FaqCategoryFragmentDoc,
    "\n  fragment eventLabelCategory on eventLabelCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": types.EventLabelCategoryFragmentDoc,
    "\n  fragment categories on CategoryInterface {\n    ...accessibilityCategory\n    ...eventTypeCategory\n    ...genreCategory\n    ...venueCategory\n    ...programCategory\n    ...faqCategory\n    ...eventLabelCategory\n  }\n": types.CategoriesFragmentDoc,
    "\n  fragment socialLinks on socialLinks_Entry {\n    __typename\n    facebook\n    twitter\n    instagram\n  }\n": types.SocialLinksFragmentDoc,
    "\n  fragment address on Address {\n    __typename\n    addressLine1\n    addressLine2\n    addressLine3\n    administrativeArea\n    countryCode\n    organization\n    organizationTaxId\n    postalCode\n    sortingCode\n    latitude\n    locality\n    longitude\n    language\n    dependentLocality\n  }\n": types.AddressFragmentDoc,
    "\n  fragment commonLink on EntryInterface {\n    id\n    uri\n    title\n  }\n": types.CommonLinkFragmentDoc,
    "\n    query commonLinks($site: [String] = \"default\") {\n      home: entry(site: $site, uri: \"__home__\") {\n        ...commonLink\n      }\n      search: entry(site: $site, section: \"search\") {\n        ...commonLink\n      }\n      articleIndex: entry(site: $site, section: \"articleIndex\") {\n        ...commonLink\n      }\n      eventIndex: entry(site: $site, section: \"page\", type: \"index\") {\n        ...commonLink\n      }\n  }\n": types.CommonLinksDocument,
    "\n  fragment document on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n": types.DocumentFragmentDoc,
    "\n  fragment siblingBranch on EntryInterface {\n    children {\n      title\n      uri\n      id\n      level\n      children {\n        title\n        uri\n        id\n        level\n        children {\n          title\n          uri\n          id\n          level\n        }\n    }\n    }\n  }\n": types.SiblingBranchFragmentDoc,
    "\n  fragment entryId on EntryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n      title\n    }\n  }\n": types.EntryIdFragmentDoc,
    "\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    title\n    sectionHandle\n    status\n    enabled\n    postDate @formatDateTime(format: \"j M, Y\")\n    parent {\n      id\n      uri\n      title\n    }\n  }\n": types.EntryBaseFragmentDoc,
    "\n  fragment articleIndexEntry on index_Entry {\n    ...articleIndexCard\n    articleSingle {\n      ...articleCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": types.ArticleIndexEntryFragmentDoc,
    "\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n    eventMultiple {\n      ...eventCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": types.ArticleEntryFragmentDoc,
    "\n  fragment homeEntry on home_Entry {\n    ...homeCard\n    blocks {\n      ...blocks\n    }\n  }\n": types.HomeEntryFragmentDoc,
    "\n  fragment venueEntry on venue_Entry {\n    ...venueCard\n    blocks {\n      ...blocks\n    }\n    venueCategory {\n      ...categories\n    }\n  }\n": types.VenueEntryFragmentDoc,
    "\n  fragment pageStandardEntry on standard_Entry {\n    ...pageStandardCard\n    typeHandle\n    siblingBranch: parent {\n      ...siblingBranch\n    }\n    ancestors {\n      id\n      title\n      uri\n      parent {\n        id\n      }\n    }\n    blocks {\n      ...blocks\n    }\n  }\n": types.PageStandardEntryFragmentDoc,
    "\n  fragment pageOverviewEntry on overview_Entry {\n    ...pageOverviewCard\n    blocks {\n      ...blocks\n    }\n  }\n": types.PageOverviewEntryFragmentDoc,
    "\n  fragment pageExpandableEntry on expandable_Entry {\n    ...pageExpandableCard\n    typeHandle\n    blocks {\n      ...blocks\n    }\n  }\n": types.PageExpandableEntryFragmentDoc,
    "\n  fragment shortlistEntry on index_Entry {\n    ...shortlistCard\n  }\n": types.ShortlistEntryFragmentDoc,
    "\n  fragment searchEntry on index_Entry {\n    ...searchCard\n  }\n": types.SearchEntryFragmentDoc,
    "\n  fragment notFoundEntry on notFound_Entry {\n    ...notFoundCard\n  }\n": types.NotFoundEntryFragmentDoc,
    "\n  fragment eventIndexEntry on index_Entry {\n    ...eventIndexCard\n  }\n": types.EventIndexEntryFragmentDoc,
    "\n  fragment eventEntry on event_Entry {\n    ...eventCard\n    status\n    blocks {\n      ...blocks\n    }\n    onSale\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    eventLabelCategory {\n      ...eventLabelCategory\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    eventPrice {\n      ...eventPriceCard\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    eventInfo {\n      ...eventInfoCard\n    }\n    faqMultiple {\n      ...faqCard\n    }\n  }\n": types.EventEntryFragmentDoc,
    "\n  fragment faqIndexEntry on faqIndex_Entry {\n    ...faqIndexCard\n  }\n": types.FaqIndexEntryFragmentDoc,
    "\n  fragment eventInfoEntry on eventInfo_Entry {\n    ...eventInfoCard\n  }\n": types.EventInfoEntryFragmentDoc,
    "\n  fragment eventPriceEntry on eventPrice_Entry {\n    ...eventPriceCard\n  }\n": types.EventPriceEntryFragmentDoc,
    "\n  fragment eventTimeEntry on eventTime_Entry {\n    ...eventTimeCard\n  }\n": types.EventTimeEntryFragmentDoc,
    "\n  fragment entries on EntryInterface {\n    ...entryBase\n    ...entrySeo\n    ...articleIndexEntry\n    ...articleEntry\n    ...homeEntry\n    ...venueEntry\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...pageExpandableEntry\n    ...shortlistEntry\n    ...searchEntry\n    ...notFoundEntry\n\n    ...eventIndexEntry\n    ...eventEntry\n    \n    ...faqIndexEntry\n    # ...faqEntry\n\n    # Event matrix entries\n    # ...eventInfoEntry\n    # ...eventPriceEntry\n    # ...eventTimeEntry\n  }\n": types.EntriesFragmentDoc,
    "\n  fragment articleIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.ArticleIndexCardFragmentDoc,
    "\n  fragment articleCard on article_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.ArticleCardFragmentDoc,
    "\n  fragment homeCard on home_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n\n    # Hero section\n    eventMultiple {\n      ...eventCard\n    }\n  }\n": types.HomeCardFragmentDoc,
    "\n  fragment venueCard on venue_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.VenueCardFragmentDoc,
    "\n  fragment pageStandardCard on standard_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.PageStandardCardFragmentDoc,
    "\n  fragment pageOverviewCard on overview_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n": types.PageOverviewCardFragmentDoc,
    "\n  fragment pageExpandableCard on expandable_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.PageExpandableCardFragmentDoc,
    "\n  fragment shortlistCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.ShortlistCardFragmentDoc,
    "\n  fragment searchCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.SearchCardFragmentDoc,
    "\n  fragment notFoundCard on notFound_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.NotFoundCardFragmentDoc,
    "\n  fragment faqCard on faq_Entry {\n    ...entryBase\n    heading\n    content: htmlContentDefault\n    faqCategory {\n      ...faqCategory\n    }\n  }\n": types.FaqCardFragmentDoc,
    "\n  fragment faqIndexCard on faqIndex_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.FaqIndexCardFragmentDoc,
    "\n  fragment eventIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.EventIndexCardFragmentDoc,
    "\n  fragment eventCard on event_Entry {\n    ...entryBase\n    status\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    videoUrl\n    genreCategory {\n      ...genreCategory\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n": types.EventCardFragmentDoc,
    "\n  fragment eventPriceCard on eventPrice_Entry {\n    ...entryBase\n    ownerId\n    title\n    minPrice\n    maxPrice\n  }\n": types.EventPriceCardFragmentDoc,
    "\n  fragment eventTimeCard on eventTime_Entry {\n    ...entryBase\n    ownerId\n    date\n    eventTimeId\n  }\n": types.EventTimeCardFragmentDoc,
    "\n  fragment eventInfoCard on eventInfo_Entry {\n    ...entryBase\n    heading\n    content: htmlContentSimple\n  }\n": types.EventInfoCardFragmentDoc,
    "\n  fragment entryCards on EntryInterface {\n    ...articleIndexCard\n    ...articleCard\n    ...homeCard  \n    ...venueCard\n    ...pageStandardCard\n    ...pageOverviewCard\n    ...pageExpandableCard\n    ...shortlistCard\n    ...searchCard\n    ...notFoundCard\n    ...eventIndexCard\n    ...eventCard\n\n    ...faqCard\n    # ...eventPriceCard\n    # ...eventTimeCard\n    # ...eventInfoCard\n  }\n": types.EntryCardsFragmentDoc,
    "\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n": types.GlobalCopyrightFragmentDoc,
    "\n  fragment globalCompanyInformation on companyInformation_GlobalSet {\n    content: htmlContentDefault\n    __typename\n    id\n    handle\n  }\n": types.GlobalCompanyInformationFragmentDoc,
    "\n  fragment globalPricingInformation on pricingInformation_GlobalSet {\n    content: htmlContentSimple\n    __typename\n    id\n    handle\n  }\n": types.GlobalPricingInformationFragmentDoc,
    "\n  fragment globalShoppingBasket on shoppingBasket_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n": types.GlobalShoppingBasketFragmentDoc,
    "\n  fragment globalProfile on profile_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n": types.GlobalProfileFragmentDoc,
    "\n  fragment globalSocialLinks on socialLinks_GlobalSet {\n    socialLinks {\n      ...socialLinks\n    }\n    __typename\n    id\n    handle\n  }\n": types.GlobalSocialLinksFragmentDoc,
    "\n  fragment globalPresenterServicesDepartment on presenterServicesDepartment_GlobalSet {\n    label\n    phoneNumber {\n      number\n    }\n    email\n    __typename\n    id\n    handle\n  }\n": types.GlobalPresenterServicesDepartmentFragmentDoc,
    "\n  fragment globals on GlobalSetInterface {\n    ...globalCopyright\n    ...globalCompanyInformation\n    ...globalSocialLinks\n    ...globalShoppingBasket\n    ...globalProfile\n    ...globalPresenterServicesDepartment\n    ...globalPricingInformation\n  }\n": types.GlobalsFragmentDoc,
    "\n  fragment asset on assetsVolume_Asset {\n    id\n    uid\n    url\n    height\n    width\n    alt\n    mimeType\n    title\n  }\n": types.AssetFragmentDoc,
    "\n  fragment imageAsset on AssetInterface {\n    ...asset\n  }\n": types.ImageAssetFragmentDoc,
    "\n  fragment linkFieldUrl on linkField_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldUrlFragmentDoc,
    "\n  fragment linkFieldEntry on linkField_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldEntryFragmentDoc,
    "\n  fragment linkFieldCustom on linkField_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldCustomFragmentDoc,
    "\n  fragment linkFieldEmail on linkField_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldEmailFragmentDoc,
    "\n  fragment linkFieldPhone on linkField_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldPhoneFragmentDoc,
    "\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldCustom\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n": types.LinkFieldFragmentDoc,
    "\n  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleUrlFragmentDoc,
    "\n  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleEntryFragmentDoc,
    "\n  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleCustomFragmentDoc,
    "\n  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleEmailFragmentDoc,
    "\n  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultiplePhoneFragmentDoc,
    "\n  fragment linkFieldMultiple on HyperLinkInterface {\n    ...linkFieldMultipleUrl\n    ...linkFieldMultipleEntry\n    ...linkFieldMultipleCustom\n    ...linkFieldMultipleEmail\n    ...linkFieldMultiplePhone\n  }\n": types.LinkFieldMultipleFragmentDoc,
    "\n  fragment navigationNode on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n      uri\n      language\n    }\n  }\n": types.NavigationNodeFragmentDoc,
    "\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n": types.SeoMetaLinksFragmentDoc,
    "\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n": types.SeoMetaTagsFragmentDoc,
    "\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n": types.SeoTitleFragmentDoc,
    "\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n": types.SeoScriptsFragmentDoc,
    "\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n": types.SeoJsonLdFragmentDoc,
    "\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n": types.SeoSiteVarsFragmentDoc,
    "\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      metaTitleContainer\n      metaJsonLdContainer\n      metaLinkContainer\n      metaTagContainer\n      metaScriptContainer\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      metaSiteVarsContainer\n    }\n  }\n": types.EntrySeoFragmentDoc,
    "\n    mutation ArticleMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_article_article_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": types.ArticleMutationDocument,
    "\n    mutation EventMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String,\n        $entrySubTitle: String,\n        $videoUrl: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $eventId: String\n        # $appendTo: ID\n        ) {\n        save_event_event_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            eventId: $eventId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySubTitle: $entrySubTitle\n            videoUrl: $videoUrl\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": types.EventMutationDocument,
    "\n    mutation OverviewMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        $programCategory: [Int]\n        # $appendTo: ID\n        ) {\n        save_page_overview_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            programCategory: $programCategory\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": types.OverviewMutationDocument,
    "\n    mutation StandardMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_standard_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": types.StandardMutationDocument,
    "\n    mutation VenueMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_venue_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n": types.VenueMutationDocument,
    "\n  query Assets {\n    assets(volume: [\"assetsVolume\", \"documentsVolume\"]) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }    \n": types.AssetsDocument,
    "\n  query categories {\n    accessibilityCategory: categories(group: \"accessibilityCategory\") {\n      ...categories\n    }\n    eventTypeCategory: categories(group: \"eventTypeCategory\") {\n      ...categories\n    }\n    genreCategory: categories(group: \"genreCategory\") {\n      ...categories\n    }\n    locationCategory: categories(group: \"locationCategory\") {\n      ...categories\n    }\n    programCategory: categories(group: \"programCategory\") {\n      ...categories\n    }\n  }\n": types.CategoriesDocument,
    "\n  query category($group: [String]) {\n    category: categories(group: $group) {\n      ...categories\n    }\n  }\n": types.CategoryDocument,
    "\n  query listEntries(\n    $site: [String] = \"default\",\n    $limit: Int,\n    $section: [String],\n    $type: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n    $relatedToCategories: [CategoryRelationCriteriaInput] = null\n  ) {\n    entries(\n      site: $site,\n      relatedToEntries: $relatedToEntries\n      relatedToCategories: $relatedToCategories\n      limit: $limit\n      section: $section\n      orderBy: $orderBy,\n      type: $type\n    ) {\n      ...entryId\n    }\n  }\n": types.ListEntriesDocument,
    "\n  query listCategories(\n    $site: [String] = \"default\",\n    $group: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n  ) {\n    categories(\n      site: $site,\n      group: $group,\n      orderBy: $orderBy,\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...categoryId\n    }\n  }\n": types.ListCategoriesDocument,
    "\n  query searchEntries(\n      $site: [String] = \"default\",\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput],\n      $fixedOrder: Boolean\n      $id: [QueryArgument]\n    ) {\n    entries(\n      site: $site,\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      fixedOrder: $fixedOrder\n      id: $id\n    ) {\n      ...entryCards\n    }\n    entryCount(\n      site: $site,\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      id: $id\n    )\n  }\n": types.SearchEntriesDocument,
    "\n    query eventPriceEntries {\n        eventPriceFieldEntries {\n            ...eventPriceCard\n        } \n    }\n": types.EventPriceEntriesDocument,
    "\n    query eventTimeEntries($date: [QueryArgument]) {\n        eventTimeFieldEntries(date: $date, orderBy: \"date ASC\") {\n            ...eventTimeCard\n        } \n    }\n": types.EventTimeEntriesDocument,
    "\n    query eventIndexEntries(\n        $genreCategory: [QueryArgument],\n        $venueCategory: [QueryArgument],\n        $accessibilityCategory: [QueryArgument],\n        $programCategory: [QueryArgument],\n        $eventTypeCategory: [QueryArgument],\n        $id: [QueryArgument],\n        $orderBy: String\n      ) {\n        eventEntries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {\n            ...eventCard\n        }\n    }\n": types.EventIndexEntriesDocument,
    "\n  query formExists($uid: [String]!) {\n    formieForm(uid: $uid) {\n      id\n      handle\n      submissionMutationName\n    }\n  }\n": types.FormExistsDocument,
    "\n  query globals($handle: [String]) {\n    globalSets(handle: $handle) {\n      ...globals\n    }\n  }\n": types.GlobalsDocument,
    "\n  query navMenus {\n    primaryNav: navigationNodes(navHandle: \"primaryNav\") {\n      ...navigationNode\n    }\n    secondaryNav: navigationNodes(navHandle: \"secondaryNav\") {\n      ...navigationNode\n    }\n    footerNav: navigationNodes(navHandle: \"footerNav\") {\n      ...navigationNode\n    }\n    footerExtrasNav: navigationNodes(navHandle: \"footerExtrasNav\") {\n      ...navigationNode\n    }\n    auxiliaryNav: navigationNodes(navHandle: \"auxiliaryNav\") {\n      ...navigationNode\n    }\n    policiesNav: navigationNodes(navHandle: \"policiesNav\") {\n      ...navigationNode\n    }\n  }\n": types.NavMenusDocument,
    "\n  query navMenu($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      ...navigationNode\n    }\n  }\n": types.NavMenuDocument,
    "\n  query allUris(\n    $section: [String], \n    $type: [String], \n    $orderBy: String, \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entries(section: $section, type: $type, orderBy: $orderBy, status: $status) {\n      id\n      uri\n      slug\n    }\n  }\n": types.AllUrisDocument,
    "\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    $status: [String] = [\"live\"]\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy, status: $status) {\n      ...entries\n    }\n  }\n": types.ContentByUriDocument,
    "\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $descendantOf: Int\n    $descendantDist: Int\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, descendantOf: $descendantOf, descendantDist: $descendantDist, status: $status) {\n      ...entries\n    }\n  }\n": types.ContentByTypeDocument,
    "\n  query entryExists(\n    $uri: [String], \n    $slug: [String], \n    $id: [QueryArgument], \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entry(uri: $uri, id: $id, slug: $slug, status: $status, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n": types.EntryExistsDocument,
    "\n  query relatedEntries(\n    $site: [String] = \"default\"\n    $exclude: QueryArgument\n    $section: [String]\n    $categoryId: [QueryArgument]\n    $parentId: Int\n    $orderBy: String\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(\n      site: $site\n      limit: 4\n      id: [\"not\", $exclude]\n      relatedTo: $categoryId\n      section: $section\n      orderBy: $orderBy\n      descendantOf: $parentId\n      status: $status\n    ) {\n      ...entryCards\n    }\n  }\n": types.RelatedEntriesDocument,
    "\n  query allImportedEvents(\n    $orderBy: String, \n    $status: [String]\n    ) {\n    eventEntries(orderBy: $orderBy, status: $status) {\n      ... on event_Entry {\n        title\n        eventId\n      }\n    }\n  }\n": types.AllImportedEventsDocument,
    "\n  query AssetByImportId($importId: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }\n": types.AssetByImportIdDocument,
    "\n  query AssetCheck($importId: [QueryArgument], $id: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, id: $id, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n        url\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n        url\n      }\n    }\n  }\n": types.AssetCheckDocument,
    "\n  query CategoryByImportId($importId: [QueryArgument], $group: [String]) {\n    category(importId: $importId, group: $group) {\n      ... on venueCategory_Category {\n      id\n    }\n    ... on programCategory_Category {\n      id\n      importId\n    }\n    ... on genreCategory_Category {\n      id\n    }\n    ... on faqCategory_Category {\n      id\n    }\n    ... on eventTypeCategory_Category {\n      id\n    }\n    ... on eventLabelCategory_Category {\n      id\n    }\n    ... on accessibilityCategory_Category {\n      id\n    }\n    }\n  }\n": types.CategoryByImportIdDocument,
    "\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n    $status: [String]\n    $id: [QueryArgument]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    status: $status\n    id: $id\n    ) {\n      ...entries\n  }\n}\n": types.EntryDocument,
    "\n  query EntryByImportId($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n      ... on venue_Entry {\n        id\n        importId\n      }\n    }\n  }\n": types.EntryByImportIdDocument,
    "\n  query EntryCheck($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n    }\n  }\n": types.EntryCheckDocument,
    "\n  query Events($eventId: [QueryArgument], $type: [String], $id: [QueryArgument]) {\n    eventEntries(eventId: $eventId, type: $type, id: $id, status: [\"enabled\", \"disabled\"]) {\n      ... on event_Entry {\n        id\n        eventId\n      }\n    }\n  }\n": types.EventsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddFaqsToEvent($id: ID, $faqs: [Int]) {\n        save_event_event_Entry(id: $id, faqMultiple: $faqs) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddFaqsToEvent($id: ID, $faqs: [Int]) {\n        save_event_event_Entry(id: $id, faqMultiple: $faqs) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddProgram($id:ID, $title: String!, $importId: String!) {\n    save_programCategory_Category(id:$id, title: $title, importId: $importId) {\n        id\n        importId\n    }\n  }\n"): (typeof documents)["\n  mutation AddProgram($id:ID, $title: String!, $importId: String!) {\n    save_programCategory_Category(id:$id, title: $title, importId: $importId) {\n        id\n        importId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionItemBlock on accordionItem_Entry {\n    heading\n    content: htmlContentSimple\n  }\n"): (typeof documents)["\n  fragment accordionItemBlock on accordionItem_Entry {\n    heading\n    content: htmlContentSimple\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    accordionItem {\n      ...accordionItemBlock\n    }\n  }\n"): (typeof documents)["\n  fragment accordionBlock on blocks_accordion_BlockType {\n    accordionItem {\n      ...accordionItemBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment breakoutBoxBlock on blocks_breakoutBox_BlockType {\n    heading\n    content: htmlContentDefault\n  }\n"): (typeof documents)["\n  fragment breakoutBoxBlock on blocks_breakoutBox_BlockType {\n    heading\n    content: htmlContentDefault\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment buttonBlock on blocks_button_BlockType {\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"): (typeof documents)["\n  fragment buttonBlock on blocks_button_BlockType {\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment categoryTypeBlock on blocks_categoryType_BlockType {\n    heading\n  }\n"): (typeof documents)["\n  fragment categoryTypeBlock on blocks_categoryType_BlockType {\n    heading\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment dividerBlock on blocks_divider_BlockType {\n    __typename\n  }\n"): (typeof documents)["\n  fragment dividerBlock on blocks_divider_BlockType {\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryCardsBlock on blocks_entryCards_BlockType {\n    heading\n    simpleCardMultiple {\n      ...simpleCardBlock\n      ...externalCardBlock\n    }\n  }\n"): (typeof documents)["\n  fragment entryCardsBlock on blocks_entryCards_BlockType {\n    heading\n    simpleCardMultiple {\n      ...simpleCardBlock\n      ...externalCardBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventCreditBlock on eventCreditItem_Entry {\n    title\n    heading\n  }\n"): (typeof documents)["\n  fragment eventCreditBlock on eventCreditItem_Entry {\n    title\n    heading\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventCreditsBlock on blocks_eventCredits_BlockType {\n    heading\n    eventCreditItem {\n      ...eventCreditBlock\n    }\n  }\n"): (typeof documents)["\n  fragment eventCreditsBlock on blocks_eventCredits_BlockType {\n    heading\n    eventCreditItem {\n      ...eventCreditBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventIndex on blocks_eventIndex_BlockType {\n    heading\n\n    # Filters\n    latestEvents\n    dateRange {\n      end\n      isFuture\n      isNotPast\n      isOnGoing\n      isPast\n      start\n    }\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    genreCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    includeEventIndex: lightswitch\n  }\n"): (typeof documents)["\n  fragment eventIndex on blocks_eventIndex_BlockType {\n    heading\n\n    # Filters\n    latestEvents\n    dateRange {\n      end\n      isFuture\n      isNotPast\n      isOnGoing\n      isPast\n      start\n    }\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    genreCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    includeEventIndex: lightswitch\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventSearch on blocks_eventSearch_BlockType {\n    heading\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"): (typeof documents)["\n  fragment eventSearch on blocks_eventSearch_BlockType {\n    heading\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment expandingCardBlock on expandCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    flipAlignment\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment expandingCardBlock on expandCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    flipAlignment\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment expandingCardsBlock on blocks_expandingCards_BlockType {\n    expandCardMultiple {\n      ...expandingCardBlock\n      ...externalCardBlock\n    }\n  }\n"): (typeof documents)["\n  fragment expandingCardsBlock on blocks_expandingCards_BlockType {\n    expandCardMultiple {\n      ...expandingCardBlock\n      ...externalCardBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment externalCardBlock on externalCard_Entry {  \n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment externalCardBlock on externalCard_Entry {  \n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment faqCardsBlock on blocks_faqCards_BlockType {\n    heading\n    faqMultiple {\n      ...faqCard\n    }\n  }\n"): (typeof documents)["\n  fragment faqCardsBlock on blocks_faqCards_BlockType {\n    heading\n    faqMultiple {\n      ...faqCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment featuredEvent on blocks_featuredEvent_BlockType {\n    eventSingle {\n      ...eventCard\n    }\n  }\n"): (typeof documents)["\n  fragment featuredEvent on blocks_featuredEvent_BlockType {\n    eventSingle {\n      ...eventCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fileDownloadBlock on blocks_fileDownload_BlockType {\n    heading\n    fileMultiple {\n      ...document\n    }\n  }\n"): (typeof documents)["\n  fragment fileDownloadBlock on blocks_fileDownload_BlockType {\n    heading\n    fileMultiple {\n      ...document\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formBlock on blocks_form_BlockType {\n    heading\n    content: htmlContentSimple\n    form {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment formBlock on blocks_form_BlockType {\n    heading\n    content: htmlContentSimple\n    form {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n    flipAlignment\n    imageSingle {\n      ...image\n    }\n    lightswitch\n  }\n"): (typeof documents)["\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n    flipAlignment\n    imageSingle {\n      ...image\n    }\n    lightswitch\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageBlock on blocks_image_BlockType {\n    imageSingle {\n      ...image\n    }\n    caption\n  }\n"): (typeof documents)["\n  fragment imageBlock on blocks_image_BlockType {\n    imageSingle {\n      ...image\n    }\n    caption\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {\n    imageMultiple {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {\n    imageMultiple {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment logoAndTextItemBlock on logoAndTextItem_Entry {\n    title\n    imageMultiple {\n      ...image\n    }\n    lightswitch\n  }  \n"): (typeof documents)["\n  fragment logoAndTextItemBlock on logoAndTextItem_Entry {\n    title\n    imageMultiple {\n      ...image\n    }\n    lightswitch\n  }  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment logoAndTextBlock on blocks_logoAndText_BlockType {\n    logoAndTextItem {\n      ...logoAndTextItemBlock\n    }\n  }\n"): (typeof documents)["\n  fragment logoAndTextBlock on blocks_logoAndText_BlockType {\n    logoAndTextItem {\n      ...logoAndTextItemBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment overviewCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    imageSingle {\n      ...image\n    }\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment overviewCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    imageSingle {\n      ...image\n    }\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment overviewCardsBlock on blocks_overviewCards_BlockType {\n    overviewCardMultiple {\n      ...overviewCardBlock\n      ...externalCardBlock\n    }\n  }\n"): (typeof documents)["\n  fragment overviewCardsBlock on blocks_overviewCards_BlockType {\n    overviewCardMultiple {\n      ...overviewCardBlock\n      ...externalCardBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment quoteBlock on quoteItem_Entry {\n    heading \n    label\n  }\n"): (typeof documents)["\n  fragment quoteBlock on quoteItem_Entry {\n    heading \n    label\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {\n    quoteItem {\n      ...quoteBlock\n    }\n  }\n"): (typeof documents)["\n  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {\n    quoteItem {\n      ...quoteBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment relatedEventsBlock on blocks_relatedEvents_BlockType {\n    eventMultiple {\n      ...eventCard\n    }\n    heading\n  }\n"): (typeof documents)["\n  fragment relatedEventsBlock on blocks_relatedEvents_BlockType {\n    eventMultiple {\n      ...eventCard\n    }\n    heading\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment relatedNewsBlock on blocks_relatedNews_BlockType {\n    articleMultiple {\n      ...articleCard\n    }\n    heading\n  }\n"): (typeof documents)["\n  fragment relatedNewsBlock on blocks_relatedNews_BlockType {\n    articleMultiple {\n      ...articleCard\n    }\n    heading\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment richTextBlock on blocks_richText_BlockType {\n    heading\n    content: htmlContentDefault\n    textColorScheme {\n      label\n    }\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"): (typeof documents)["\n  fragment richTextBlock on blocks_richText_BlockType {\n    heading\n    content: htmlContentDefault\n    textColorScheme {\n      label\n    }\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment simpleCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment simpleCardBlock on entryCard_Entry {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    imageSingle {\n      ...image\n    }\n    linkField {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment tableBlock on blocks_table_BlockType {\n    id\n    table {\n      ...table\n    }\n  }\n"): (typeof documents)["\n  fragment tableBlock on blocks_table_BlockType {\n    id\n    table {\n      ...table\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment videoBlock on blocks_video_BlockType {\n    videoUrl\n  }\n"): (typeof documents)["\n  fragment videoBlock on blocks_video_BlockType {\n    videoUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment image on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      id\n      mimeType\n      title\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n"): (typeof documents)["\n  fragment image on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      id\n      mimeType\n      title\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment column on column_Entry {\n        id\n        content: htmlContentSimple\n    }\n"): (typeof documents)["\n    fragment column on column_Entry {\n        id\n        content: htmlContentSimple\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment row on row_Entry {\n        id\n        headingRow: lightswitch\n        column {\n            ...column\n        }\n    }\n"): (typeof documents)["\n    fragment row on row_Entry {\n        id\n        headingRow: lightswitch\n        column {\n            ...column\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment table on table_Entry {\n        id\n        row {\n            ...row\n        }\n    }\n"): (typeof documents)["\n    fragment table on table_Entry {\n        id\n        row {\n            ...row\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment blockBase on NeoBlockInterface {\n    id\n    __typename\n    level\n  }\n"): (typeof documents)["\n  fragment blockBase on NeoBlockInterface {\n    id\n    __typename\n    level\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment blocks on blocks_NeoField {\n    ...blockBase\n    ...richTextBlock\n    ...buttonBlock\n    ...accordionBlock\n    ...quoteCarouselBlock\n    ...dividerBlock\n    ...breakoutBoxBlock\n    ...tableBlock\n\n    # Event fragments\n    ...categoryTypeBlock\n    ...eventIndex\n    ...eventSearch\n    ...featuredEvent\n    ...eventCreditsBlock\n\n    # Media fragments\n    ...imageAndTextBlock\n    ...logoAndTextBlock\n    ...imageBlock\n    ...imageCarouselBlock\n    ...videoBlock\n    ...fileDownloadBlock\n    ...formBlock\n\n    # Related\n    ...relatedNewsBlock\n    ...relatedEventsBlock\n\n    # Cards\n    ...entryCardsBlock\n    ...expandingCardsBlock\n    ...overviewCardsBlock\n    ...faqCardsBlock\n  }\n"): (typeof documents)["\n  fragment blocks on blocks_NeoField {\n    ...blockBase\n    ...richTextBlock\n    ...buttonBlock\n    ...accordionBlock\n    ...quoteCarouselBlock\n    ...dividerBlock\n    ...breakoutBoxBlock\n    ...tableBlock\n\n    # Event fragments\n    ...categoryTypeBlock\n    ...eventIndex\n    ...eventSearch\n    ...featuredEvent\n    ...eventCreditsBlock\n\n    # Media fragments\n    ...imageAndTextBlock\n    ...logoAndTextBlock\n    ...imageBlock\n    ...imageCarouselBlock\n    ...videoBlock\n    ...fileDownloadBlock\n    ...formBlock\n\n    # Related\n    ...relatedNewsBlock\n    ...relatedEventsBlock\n\n    # Cards\n    ...entryCardsBlock\n    ...expandingCardsBlock\n    ...overviewCardsBlock\n    ...faqCardsBlock\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment categoryId on CategoryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment categoryId on CategoryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accessibilityCategory on accessibilityCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"): (typeof documents)["\n  fragment accessibilityCategory on accessibilityCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventTypeCategory on eventTypeCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"): (typeof documents)["\n  fragment eventTypeCategory on eventTypeCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment genreCategory on genreCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    imageSingle {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment genreCategory on genreCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    imageSingle {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment venueCategory on venueCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    address {\n      ...address\n    }\n    venueSingle {\n      ...venueCard\n    }\n    venueShorthand\n    bookingUrl: externalUrl\n    capacity: htmlContentSimple\n    venueInfo {\n      ...eventInfoCard\n    }\n  }\n"): (typeof documents)["\n  fragment venueCategory on venueCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    address {\n      ...address\n    }\n    venueSingle {\n      ...venueCard\n    }\n    venueShorthand\n    bookingUrl: externalUrl\n    capacity: htmlContentSimple\n    venueInfo {\n      ...eventInfoCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment programCategory on programCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment programCategory on programCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment faqCategory on faqCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"): (typeof documents)["\n  fragment faqCategory on faqCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventLabelCategory on eventLabelCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"): (typeof documents)["\n  fragment eventLabelCategory on eventLabelCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment categories on CategoryInterface {\n    ...accessibilityCategory\n    ...eventTypeCategory\n    ...genreCategory\n    ...venueCategory\n    ...programCategory\n    ...faqCategory\n    ...eventLabelCategory\n  }\n"): (typeof documents)["\n  fragment categories on CategoryInterface {\n    ...accessibilityCategory\n    ...eventTypeCategory\n    ...genreCategory\n    ...venueCategory\n    ...programCategory\n    ...faqCategory\n    ...eventLabelCategory\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment socialLinks on socialLinks_Entry {\n    __typename\n    facebook\n    twitter\n    instagram\n  }\n"): (typeof documents)["\n  fragment socialLinks on socialLinks_Entry {\n    __typename\n    facebook\n    twitter\n    instagram\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment address on Address {\n    __typename\n    addressLine1\n    addressLine2\n    addressLine3\n    administrativeArea\n    countryCode\n    organization\n    organizationTaxId\n    postalCode\n    sortingCode\n    latitude\n    locality\n    longitude\n    language\n    dependentLocality\n  }\n"): (typeof documents)["\n  fragment address on Address {\n    __typename\n    addressLine1\n    addressLine2\n    addressLine3\n    administrativeArea\n    countryCode\n    organization\n    organizationTaxId\n    postalCode\n    sortingCode\n    latitude\n    locality\n    longitude\n    language\n    dependentLocality\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment commonLink on EntryInterface {\n    id\n    uri\n    title\n  }\n"): (typeof documents)["\n  fragment commonLink on EntryInterface {\n    id\n    uri\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query commonLinks($site: [String] = \"default\") {\n      home: entry(site: $site, uri: \"__home__\") {\n        ...commonLink\n      }\n      search: entry(site: $site, section: \"search\") {\n        ...commonLink\n      }\n      articleIndex: entry(site: $site, section: \"articleIndex\") {\n        ...commonLink\n      }\n      eventIndex: entry(site: $site, section: \"page\", type: \"index\") {\n        ...commonLink\n      }\n  }\n"): (typeof documents)["\n    query commonLinks($site: [String] = \"default\") {\n      home: entry(site: $site, uri: \"__home__\") {\n        ...commonLink\n      }\n      search: entry(site: $site, section: \"search\") {\n        ...commonLink\n      }\n      articleIndex: entry(site: $site, section: \"articleIndex\") {\n        ...commonLink\n      }\n      eventIndex: entry(site: $site, section: \"page\", type: \"index\") {\n        ...commonLink\n      }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment document on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n"): (typeof documents)["\n  fragment document on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment siblingBranch on EntryInterface {\n    children {\n      title\n      uri\n      id\n      level\n      children {\n        title\n        uri\n        id\n        level\n        children {\n          title\n          uri\n          id\n          level\n        }\n    }\n    }\n  }\n"): (typeof documents)["\n  fragment siblingBranch on EntryInterface {\n    children {\n      title\n      uri\n      id\n      level\n      children {\n        title\n        uri\n        id\n        level\n        children {\n          title\n          uri\n          id\n          level\n        }\n    }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryId on EntryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  fragment entryId on EntryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n      title\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    title\n    sectionHandle\n    status\n    enabled\n    postDate @formatDateTime(format: \"j M, Y\")\n    parent {\n      id\n      uri\n      title\n    }\n  }\n"): (typeof documents)["\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    title\n    sectionHandle\n    status\n    enabled\n    postDate @formatDateTime(format: \"j M, Y\")\n    parent {\n      id\n      uri\n      title\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexEntry on index_Entry {\n    ...articleIndexCard\n    articleSingle {\n      ...articleCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"): (typeof documents)["\n  fragment articleIndexEntry on index_Entry {\n    ...articleIndexCard\n    articleSingle {\n      ...articleCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n    eventMultiple {\n      ...eventCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"): (typeof documents)["\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n    eventMultiple {\n      ...eventCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment homeEntry on home_Entry {\n    ...homeCard\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment homeEntry on home_Entry {\n    ...homeCard\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment venueEntry on venue_Entry {\n    ...venueCard\n    blocks {\n      ...blocks\n    }\n    venueCategory {\n      ...categories\n    }\n  }\n"): (typeof documents)["\n  fragment venueEntry on venue_Entry {\n    ...venueCard\n    blocks {\n      ...blocks\n    }\n    venueCategory {\n      ...categories\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageStandardEntry on standard_Entry {\n    ...pageStandardCard\n    typeHandle\n    siblingBranch: parent {\n      ...siblingBranch\n    }\n    ancestors {\n      id\n      title\n      uri\n      parent {\n        id\n      }\n    }\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment pageStandardEntry on standard_Entry {\n    ...pageStandardCard\n    typeHandle\n    siblingBranch: parent {\n      ...siblingBranch\n    }\n    ancestors {\n      id\n      title\n      uri\n      parent {\n        id\n      }\n    }\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageOverviewEntry on overview_Entry {\n    ...pageOverviewCard\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment pageOverviewEntry on overview_Entry {\n    ...pageOverviewCard\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageExpandableEntry on expandable_Entry {\n    ...pageExpandableCard\n    typeHandle\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment pageExpandableEntry on expandable_Entry {\n    ...pageExpandableCard\n    typeHandle\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment shortlistEntry on index_Entry {\n    ...shortlistCard\n  }\n"): (typeof documents)["\n  fragment shortlistEntry on index_Entry {\n    ...shortlistCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment searchEntry on index_Entry {\n    ...searchCard\n  }\n"): (typeof documents)["\n  fragment searchEntry on index_Entry {\n    ...searchCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment notFoundEntry on notFound_Entry {\n    ...notFoundCard\n  }\n"): (typeof documents)["\n  fragment notFoundEntry on notFound_Entry {\n    ...notFoundCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventIndexEntry on index_Entry {\n    ...eventIndexCard\n  }\n"): (typeof documents)["\n  fragment eventIndexEntry on index_Entry {\n    ...eventIndexCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventEntry on event_Entry {\n    ...eventCard\n    status\n    blocks {\n      ...blocks\n    }\n    onSale\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    eventLabelCategory {\n      ...eventLabelCategory\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    eventPrice {\n      ...eventPriceCard\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    eventInfo {\n      ...eventInfoCard\n    }\n    faqMultiple {\n      ...faqCard\n    }\n  }\n"): (typeof documents)["\n  fragment eventEntry on event_Entry {\n    ...eventCard\n    status\n    blocks {\n      ...blocks\n    }\n    onSale\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    eventLabelCategory {\n      ...eventLabelCategory\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    eventPrice {\n      ...eventPriceCard\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    eventInfo {\n      ...eventInfoCard\n    }\n    faqMultiple {\n      ...faqCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment faqIndexEntry on faqIndex_Entry {\n    ...faqIndexCard\n  }\n"): (typeof documents)["\n  fragment faqIndexEntry on faqIndex_Entry {\n    ...faqIndexCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventInfoEntry on eventInfo_Entry {\n    ...eventInfoCard\n  }\n"): (typeof documents)["\n  fragment eventInfoEntry on eventInfo_Entry {\n    ...eventInfoCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventPriceEntry on eventPrice_Entry {\n    ...eventPriceCard\n  }\n"): (typeof documents)["\n  fragment eventPriceEntry on eventPrice_Entry {\n    ...eventPriceCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventTimeEntry on eventTime_Entry {\n    ...eventTimeCard\n  }\n"): (typeof documents)["\n  fragment eventTimeEntry on eventTime_Entry {\n    ...eventTimeCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entries on EntryInterface {\n    ...entryBase\n    ...entrySeo\n    ...articleIndexEntry\n    ...articleEntry\n    ...homeEntry\n    ...venueEntry\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...pageExpandableEntry\n    ...shortlistEntry\n    ...searchEntry\n    ...notFoundEntry\n\n    ...eventIndexEntry\n    ...eventEntry\n    \n    ...faqIndexEntry\n    # ...faqEntry\n\n    # Event matrix entries\n    # ...eventInfoEntry\n    # ...eventPriceEntry\n    # ...eventTimeEntry\n  }\n"): (typeof documents)["\n  fragment entries on EntryInterface {\n    ...entryBase\n    ...entrySeo\n    ...articleIndexEntry\n    ...articleEntry\n    ...homeEntry\n    ...venueEntry\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...pageExpandableEntry\n    ...shortlistEntry\n    ...searchEntry\n    ...notFoundEntry\n\n    ...eventIndexEntry\n    ...eventEntry\n    \n    ...faqIndexEntry\n    # ...faqEntry\n\n    # Event matrix entries\n    # ...eventInfoEntry\n    # ...eventPriceEntry\n    # ...eventTimeEntry\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment articleIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleCard on article_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment articleCard on article_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment homeCard on home_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n\n    # Hero section\n    eventMultiple {\n      ...eventCard\n    }\n  }\n"): (typeof documents)["\n  fragment homeCard on home_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n\n    # Hero section\n    eventMultiple {\n      ...eventCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment venueCard on venue_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment venueCard on venue_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageStandardCard on standard_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment pageStandardCard on standard_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageOverviewCard on overview_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n"): (typeof documents)["\n  fragment pageOverviewCard on overview_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageExpandableCard on expandable_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment pageExpandableCard on expandable_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment shortlistCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment shortlistCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment searchCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment searchCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment notFoundCard on notFound_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment notFoundCard on notFound_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment faqCard on faq_Entry {\n    ...entryBase\n    heading\n    content: htmlContentDefault\n    faqCategory {\n      ...faqCategory\n    }\n  }\n"): (typeof documents)["\n  fragment faqCard on faq_Entry {\n    ...entryBase\n    heading\n    content: htmlContentDefault\n    faqCategory {\n      ...faqCategory\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment faqIndexCard on faqIndex_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment faqIndexCard on faqIndex_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment eventIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventCard on event_Entry {\n    ...entryBase\n    status\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    videoUrl\n    genreCategory {\n      ...genreCategory\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n"): (typeof documents)["\n  fragment eventCard on event_Entry {\n    ...entryBase\n    status\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    videoUrl\n    genreCategory {\n      ...genreCategory\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...programCategory\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventPriceCard on eventPrice_Entry {\n    ...entryBase\n    ownerId\n    title\n    minPrice\n    maxPrice\n  }\n"): (typeof documents)["\n  fragment eventPriceCard on eventPrice_Entry {\n    ...entryBase\n    ownerId\n    title\n    minPrice\n    maxPrice\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventTimeCard on eventTime_Entry {\n    ...entryBase\n    ownerId\n    date\n    eventTimeId\n  }\n"): (typeof documents)["\n  fragment eventTimeCard on eventTime_Entry {\n    ...entryBase\n    ownerId\n    date\n    eventTimeId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventInfoCard on eventInfo_Entry {\n    ...entryBase\n    heading\n    content: htmlContentSimple\n  }\n"): (typeof documents)["\n  fragment eventInfoCard on eventInfo_Entry {\n    ...entryBase\n    heading\n    content: htmlContentSimple\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryCards on EntryInterface {\n    ...articleIndexCard\n    ...articleCard\n    ...homeCard  \n    ...venueCard\n    ...pageStandardCard\n    ...pageOverviewCard\n    ...pageExpandableCard\n    ...shortlistCard\n    ...searchCard\n    ...notFoundCard\n    ...eventIndexCard\n    ...eventCard\n\n    ...faqCard\n    # ...eventPriceCard\n    # ...eventTimeCard\n    # ...eventInfoCard\n  }\n"): (typeof documents)["\n  fragment entryCards on EntryInterface {\n    ...articleIndexCard\n    ...articleCard\n    ...homeCard  \n    ...venueCard\n    ...pageStandardCard\n    ...pageOverviewCard\n    ...pageExpandableCard\n    ...shortlistCard\n    ...searchCard\n    ...notFoundCard\n    ...eventIndexCard\n    ...eventCard\n\n    ...faqCard\n    # ...eventPriceCard\n    # ...eventTimeCard\n    # ...eventInfoCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalCompanyInformation on companyInformation_GlobalSet {\n    content: htmlContentDefault\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalCompanyInformation on companyInformation_GlobalSet {\n    content: htmlContentDefault\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalPricingInformation on pricingInformation_GlobalSet {\n    content: htmlContentSimple\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalPricingInformation on pricingInformation_GlobalSet {\n    content: htmlContentSimple\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalShoppingBasket on shoppingBasket_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalShoppingBasket on shoppingBasket_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalProfile on profile_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalProfile on profile_GlobalSet {\n    externalUrl\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalSocialLinks on socialLinks_GlobalSet {\n    socialLinks {\n      ...socialLinks\n    }\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalSocialLinks on socialLinks_GlobalSet {\n    socialLinks {\n      ...socialLinks\n    }\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalPresenterServicesDepartment on presenterServicesDepartment_GlobalSet {\n    label\n    phoneNumber {\n      number\n    }\n    email\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalPresenterServicesDepartment on presenterServicesDepartment_GlobalSet {\n    label\n    phoneNumber {\n      number\n    }\n    email\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globals on GlobalSetInterface {\n    ...globalCopyright\n    ...globalCompanyInformation\n    ...globalSocialLinks\n    ...globalShoppingBasket\n    ...globalProfile\n    ...globalPresenterServicesDepartment\n    ...globalPricingInformation\n  }\n"): (typeof documents)["\n  fragment globals on GlobalSetInterface {\n    ...globalCopyright\n    ...globalCompanyInformation\n    ...globalSocialLinks\n    ...globalShoppingBasket\n    ...globalProfile\n    ...globalPresenterServicesDepartment\n    ...globalPricingInformation\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment asset on assetsVolume_Asset {\n    id\n    uid\n    url\n    height\n    width\n    alt\n    mimeType\n    title\n  }\n"): (typeof documents)["\n  fragment asset on assetsVolume_Asset {\n    id\n    uid\n    url\n    height\n    width\n    alt\n    mimeType\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageAsset on AssetInterface {\n    ...asset\n  }\n"): (typeof documents)["\n  fragment imageAsset on AssetInterface {\n    ...asset\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldUrl on linkField_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldUrl on linkField_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldEntry on linkField_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldEntry on linkField_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldCustom on linkField_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldCustom on linkField_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldEmail on linkField_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldEmail on linkField_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldPhone on linkField_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldPhone on linkField_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldCustom\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n"): (typeof documents)["\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldCustom\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultiple on HyperLinkInterface {\n    ...linkFieldMultipleUrl\n    ...linkFieldMultipleEntry\n    ...linkFieldMultipleCustom\n    ...linkFieldMultipleEmail\n    ...linkFieldMultiplePhone\n  }\n"): (typeof documents)["\n  fragment linkFieldMultiple on HyperLinkInterface {\n    ...linkFieldMultipleUrl\n    ...linkFieldMultipleEntry\n    ...linkFieldMultipleCustom\n    ...linkFieldMultipleEmail\n    ...linkFieldMultiplePhone\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment navigationNode on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n      uri\n      language\n    }\n  }\n"): (typeof documents)["\n  fragment navigationNode on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n      uri\n      language\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n"): (typeof documents)["\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n"): (typeof documents)["\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n"): (typeof documents)["\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n"): (typeof documents)["\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n"): (typeof documents)["\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n"): (typeof documents)["\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      metaTitleContainer\n      metaJsonLdContainer\n      metaLinkContainer\n      metaTagContainer\n      metaScriptContainer\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      metaSiteVarsContainer\n    }\n  }\n"): (typeof documents)["\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      metaTitleContainer\n      metaJsonLdContainer\n      metaLinkContainer\n      metaTagContainer\n      metaScriptContainer\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      metaSiteVarsContainer\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ArticleMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_article_article_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ArticleMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_article_article_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation EventMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String,\n        $entrySubTitle: String,\n        $videoUrl: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $eventId: String\n        # $appendTo: ID\n        ) {\n        save_event_event_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            eventId: $eventId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySubTitle: $entrySubTitle\n            videoUrl: $videoUrl\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation EventMutation(\n        $blocks: blocks_NeoInput,\n        # $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String,\n        $entrySubTitle: String,\n        $videoUrl: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $eventId: String\n        # $appendTo: ID\n        ) {\n        save_event_event_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            eventId: $eventId\n            # appendTo: $appendTo\n            # colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySubTitle: $entrySubTitle\n            videoUrl: $videoUrl\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation OverviewMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        $programCategory: [Int]\n        # $appendTo: ID\n        ) {\n        save_page_overview_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            programCategory: $programCategory\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation OverviewMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        $programCategory: [Int]\n        # $appendTo: ID\n        ) {\n        save_page_overview_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            programCategory: $programCategory\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation StandardMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_standard_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation StandardMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_standard_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation VenueMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_venue_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation VenueMutation(\n        $blocks: blocks_NeoInput,\n        $colorScheme: String, \n        $entryTitle: String, \n        $entrySummary: String, \n        $entryImage: [Int],\n        $slug: String,\n        $title: String,\n        $authorId: ID,\n        $id: ID,\n        $importId: String\n        # $appendTo: ID\n        ) {\n        save_page_venue_Entry(\n            blocks: $blocks\n            slug: $slug\n            title: $title\n            authorId: $authorId\n            id: $id\n            importId: $importId\n            # appendTo: $appendTo\n            colorScheme: $colorScheme\n            entryTitle: $entryTitle\n            entrySummary: $entrySummary\n            entryImage: $entryImage\n            ) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Assets {\n    assets(volume: [\"assetsVolume\", \"documentsVolume\"]) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }    \n"): (typeof documents)["\n  query Assets {\n    assets(volume: [\"assetsVolume\", \"documentsVolume\"]) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }    \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query categories {\n    accessibilityCategory: categories(group: \"accessibilityCategory\") {\n      ...categories\n    }\n    eventTypeCategory: categories(group: \"eventTypeCategory\") {\n      ...categories\n    }\n    genreCategory: categories(group: \"genreCategory\") {\n      ...categories\n    }\n    locationCategory: categories(group: \"locationCategory\") {\n      ...categories\n    }\n    programCategory: categories(group: \"programCategory\") {\n      ...categories\n    }\n  }\n"): (typeof documents)["\n  query categories {\n    accessibilityCategory: categories(group: \"accessibilityCategory\") {\n      ...categories\n    }\n    eventTypeCategory: categories(group: \"eventTypeCategory\") {\n      ...categories\n    }\n    genreCategory: categories(group: \"genreCategory\") {\n      ...categories\n    }\n    locationCategory: categories(group: \"locationCategory\") {\n      ...categories\n    }\n    programCategory: categories(group: \"programCategory\") {\n      ...categories\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query category($group: [String]) {\n    category: categories(group: $group) {\n      ...categories\n    }\n  }\n"): (typeof documents)["\n  query category($group: [String]) {\n    category: categories(group: $group) {\n      ...categories\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query listEntries(\n    $site: [String] = \"default\",\n    $limit: Int,\n    $section: [String],\n    $type: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n    $relatedToCategories: [CategoryRelationCriteriaInput] = null\n  ) {\n    entries(\n      site: $site,\n      relatedToEntries: $relatedToEntries\n      relatedToCategories: $relatedToCategories\n      limit: $limit\n      section: $section\n      orderBy: $orderBy,\n      type: $type\n    ) {\n      ...entryId\n    }\n  }\n"): (typeof documents)["\n  query listEntries(\n    $site: [String] = \"default\",\n    $limit: Int,\n    $section: [String],\n    $type: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n    $relatedToCategories: [CategoryRelationCriteriaInput] = null\n  ) {\n    entries(\n      site: $site,\n      relatedToEntries: $relatedToEntries\n      relatedToCategories: $relatedToCategories\n      limit: $limit\n      section: $section\n      orderBy: $orderBy,\n      type: $type\n    ) {\n      ...entryId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query listCategories(\n    $site: [String] = \"default\",\n    $group: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n  ) {\n    categories(\n      site: $site,\n      group: $group,\n      orderBy: $orderBy,\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...categoryId\n    }\n  }\n"): (typeof documents)["\n  query listCategories(\n    $site: [String] = \"default\",\n    $group: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n  ) {\n    categories(\n      site: $site,\n      group: $group,\n      orderBy: $orderBy,\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...categoryId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query searchEntries(\n      $site: [String] = \"default\",\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput],\n      $fixedOrder: Boolean\n      $id: [QueryArgument]\n    ) {\n    entries(\n      site: $site,\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      fixedOrder: $fixedOrder\n      id: $id\n    ) {\n      ...entryCards\n    }\n    entryCount(\n      site: $site,\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      id: $id\n    )\n  }\n"): (typeof documents)["\n  query searchEntries(\n      $site: [String] = \"default\",\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput],\n      $fixedOrder: Boolean\n      $id: [QueryArgument]\n    ) {\n    entries(\n      site: $site,\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      fixedOrder: $fixedOrder\n      id: $id\n    ) {\n      ...entryCards\n    }\n    entryCount(\n      site: $site,\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      id: $id\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query eventPriceEntries {\n        eventPriceFieldEntries {\n            ...eventPriceCard\n        } \n    }\n"): (typeof documents)["\n    query eventPriceEntries {\n        eventPriceFieldEntries {\n            ...eventPriceCard\n        } \n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query eventTimeEntries($date: [QueryArgument]) {\n        eventTimeFieldEntries(date: $date, orderBy: \"date ASC\") {\n            ...eventTimeCard\n        } \n    }\n"): (typeof documents)["\n    query eventTimeEntries($date: [QueryArgument]) {\n        eventTimeFieldEntries(date: $date, orderBy: \"date ASC\") {\n            ...eventTimeCard\n        } \n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query eventIndexEntries(\n        $genreCategory: [QueryArgument],\n        $venueCategory: [QueryArgument],\n        $accessibilityCategory: [QueryArgument],\n        $programCategory: [QueryArgument],\n        $eventTypeCategory: [QueryArgument],\n        $id: [QueryArgument],\n        $orderBy: String\n      ) {\n        eventEntries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {\n            ...eventCard\n        }\n    }\n"): (typeof documents)["\n    query eventIndexEntries(\n        $genreCategory: [QueryArgument],\n        $venueCategory: [QueryArgument],\n        $accessibilityCategory: [QueryArgument],\n        $programCategory: [QueryArgument],\n        $eventTypeCategory: [QueryArgument],\n        $id: [QueryArgument],\n        $orderBy: String\n      ) {\n        eventEntries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {\n            ...eventCard\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query formExists($uid: [String]!) {\n    formieForm(uid: $uid) {\n      id\n      handle\n      submissionMutationName\n    }\n  }\n"): (typeof documents)["\n  query formExists($uid: [String]!) {\n    formieForm(uid: $uid) {\n      id\n      handle\n      submissionMutationName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query globals($handle: [String]) {\n    globalSets(handle: $handle) {\n      ...globals\n    }\n  }\n"): (typeof documents)["\n  query globals($handle: [String]) {\n    globalSets(handle: $handle) {\n      ...globals\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query navMenus {\n    primaryNav: navigationNodes(navHandle: \"primaryNav\") {\n      ...navigationNode\n    }\n    secondaryNav: navigationNodes(navHandle: \"secondaryNav\") {\n      ...navigationNode\n    }\n    footerNav: navigationNodes(navHandle: \"footerNav\") {\n      ...navigationNode\n    }\n    footerExtrasNav: navigationNodes(navHandle: \"footerExtrasNav\") {\n      ...navigationNode\n    }\n    auxiliaryNav: navigationNodes(navHandle: \"auxiliaryNav\") {\n      ...navigationNode\n    }\n    policiesNav: navigationNodes(navHandle: \"policiesNav\") {\n      ...navigationNode\n    }\n  }\n"): (typeof documents)["\n  query navMenus {\n    primaryNav: navigationNodes(navHandle: \"primaryNav\") {\n      ...navigationNode\n    }\n    secondaryNav: navigationNodes(navHandle: \"secondaryNav\") {\n      ...navigationNode\n    }\n    footerNav: navigationNodes(navHandle: \"footerNav\") {\n      ...navigationNode\n    }\n    footerExtrasNav: navigationNodes(navHandle: \"footerExtrasNav\") {\n      ...navigationNode\n    }\n    auxiliaryNav: navigationNodes(navHandle: \"auxiliaryNav\") {\n      ...navigationNode\n    }\n    policiesNav: navigationNodes(navHandle: \"policiesNav\") {\n      ...navigationNode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query navMenu($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      ...navigationNode\n    }\n  }\n"): (typeof documents)["\n  query navMenu($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      ...navigationNode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allUris(\n    $section: [String], \n    $type: [String], \n    $orderBy: String, \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entries(section: $section, type: $type, orderBy: $orderBy, status: $status) {\n      id\n      uri\n      slug\n    }\n  }\n"): (typeof documents)["\n  query allUris(\n    $section: [String], \n    $type: [String], \n    $orderBy: String, \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entries(section: $section, type: $type, orderBy: $orderBy, status: $status) {\n      id\n      uri\n      slug\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    $status: [String] = [\"live\"]\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy, status: $status) {\n      ...entries\n    }\n  }\n"): (typeof documents)["\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    $status: [String] = [\"live\"]\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy, status: $status) {\n      ...entries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $descendantOf: Int\n    $descendantDist: Int\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, descendantOf: $descendantOf, descendantDist: $descendantDist, status: $status) {\n      ...entries\n    }\n  }\n"): (typeof documents)["\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $descendantOf: Int\n    $descendantDist: Int\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, descendantOf: $descendantOf, descendantDist: $descendantDist, status: $status) {\n      ...entries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query entryExists(\n    $uri: [String], \n    $slug: [String], \n    $id: [QueryArgument], \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entry(uri: $uri, id: $id, slug: $slug, status: $status, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n"): (typeof documents)["\n  query entryExists(\n    $uri: [String], \n    $slug: [String], \n    $id: [QueryArgument], \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entry(uri: $uri, id: $id, slug: $slug, status: $status, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query relatedEntries(\n    $site: [String] = \"default\"\n    $exclude: QueryArgument\n    $section: [String]\n    $categoryId: [QueryArgument]\n    $parentId: Int\n    $orderBy: String\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(\n      site: $site\n      limit: 4\n      id: [\"not\", $exclude]\n      relatedTo: $categoryId\n      section: $section\n      orderBy: $orderBy\n      descendantOf: $parentId\n      status: $status\n    ) {\n      ...entryCards\n    }\n  }\n"): (typeof documents)["\n  query relatedEntries(\n    $site: [String] = \"default\"\n    $exclude: QueryArgument\n    $section: [String]\n    $categoryId: [QueryArgument]\n    $parentId: Int\n    $orderBy: String\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(\n      site: $site\n      limit: 4\n      id: [\"not\", $exclude]\n      relatedTo: $categoryId\n      section: $section\n      orderBy: $orderBy\n      descendantOf: $parentId\n      status: $status\n    ) {\n      ...entryCards\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allImportedEvents(\n    $orderBy: String, \n    $status: [String]\n    ) {\n    eventEntries(orderBy: $orderBy, status: $status) {\n      ... on event_Entry {\n        title\n        eventId\n      }\n    }\n  }\n"): (typeof documents)["\n  query allImportedEvents(\n    $orderBy: String, \n    $status: [String]\n    ) {\n    eventEntries(orderBy: $orderBy, status: $status) {\n      ... on event_Entry {\n        title\n        eventId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AssetByImportId($importId: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }\n"): (typeof documents)["\n  query AssetByImportId($importId: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AssetCheck($importId: [QueryArgument], $id: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, id: $id, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n        url\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query AssetCheck($importId: [QueryArgument], $id: [QueryArgument], $volumeId: [QueryArgument]) {\n    assets(importId: $importId, id: $id, volumeId: $volumeId) {\n      ... on assetsVolume_Asset {\n        id\n        importId\n        url\n      }\n      ... on documentsVolume_Asset {\n        id\n        importId\n        url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CategoryByImportId($importId: [QueryArgument], $group: [String]) {\n    category(importId: $importId, group: $group) {\n      ... on venueCategory_Category {\n      id\n    }\n    ... on programCategory_Category {\n      id\n      importId\n    }\n    ... on genreCategory_Category {\n      id\n    }\n    ... on faqCategory_Category {\n      id\n    }\n    ... on eventTypeCategory_Category {\n      id\n    }\n    ... on eventLabelCategory_Category {\n      id\n    }\n    ... on accessibilityCategory_Category {\n      id\n    }\n    }\n  }\n"): (typeof documents)["\n  query CategoryByImportId($importId: [QueryArgument], $group: [String]) {\n    category(importId: $importId, group: $group) {\n      ... on venueCategory_Category {\n      id\n    }\n    ... on programCategory_Category {\n      id\n      importId\n    }\n    ... on genreCategory_Category {\n      id\n    }\n    ... on faqCategory_Category {\n      id\n    }\n    ... on eventTypeCategory_Category {\n      id\n    }\n    ... on eventLabelCategory_Category {\n      id\n    }\n    ... on accessibilityCategory_Category {\n      id\n    }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n    $status: [String]\n    $id: [QueryArgument]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    status: $status\n    id: $id\n    ) {\n      ...entries\n  }\n}\n"): (typeof documents)["\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n    $status: [String]\n    $id: [QueryArgument]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    status: $status\n    id: $id\n    ) {\n      ...entries\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EntryByImportId($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n      ... on venue_Entry {\n        id\n        importId\n      }\n    }\n  }\n"): (typeof documents)["\n  query EntryByImportId($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n      ... on venue_Entry {\n        id\n        importId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EntryCheck($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n    }\n  }\n"): (typeof documents)["\n  query EntryCheck($importId: [QueryArgument], $type: [String]) {\n    pageEntries(importId: $importId, type: $type) {\n      ... on standard_Entry {\n        id\n        importId\n      }\n      ... on overview_Entry {\n        id\n        importId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Events($eventId: [QueryArgument], $type: [String], $id: [QueryArgument]) {\n    eventEntries(eventId: $eventId, type: $type, id: $id, status: [\"enabled\", \"disabled\"]) {\n      ... on event_Entry {\n        id\n        eventId\n      }\n    }\n  }\n"): (typeof documents)["\n  query Events($eventId: [QueryArgument], $type: [String], $id: [QueryArgument]) {\n    eventEntries(eventId: $eventId, type: $type, id: $id, status: [\"enabled\", \"disabled\"]) {\n      ... on event_Entry {\n        id\n        eventId\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;